@import '../../../../styles/variables.scss';
.noTender{
    padding: 20px;
    display: flex;
    min-height: calc(100svh - 100px);
    justify-content: center;
    align-items: center;
}

.title{
    color: var(--tg-theme-text-color) !important;
}
