@import '../../../../styles/variables.scss';


.order{
    display: flex;
    flex-direction: column;
    padding: 15px;
    background: var(--tg-theme-bg-color);
    box-shadow: 0px 0px 4px rgba(var(--tg-theme-bg-color),0.5);
    &_title{
        color: var(--tg-theme-text-color);
        font-weight: 600;
        line-height: 1.4;
        font-size: 1.1em;
    }
    &_categories{
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
        padding: 5px 0;
        margin: 10px 0;
        color: var(--tg-theme-text-color);
        font-size: 0.95em;
        span{
            font-style: italic;
        }
    }
    &_desc{
        padding: 5px 0;
        white-space: pre-line;
    }
    &_footer{
        padding: 5px 0;
    }
}
