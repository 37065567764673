@import '../../../styles/variables.scss';

.pageNotFound{
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.text{
    text-align: center;
    color: var(--tg-theme-text-color);
}

.btn{
    background: var(--tg-theme-button-color);
    color: var(--tg-theme-button-text-color);
    border: none;
    outline: none;
    cursor: pointer;
    padding: 7px 10px;
    border-radius: 4px;
}

.buttons{
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 20px;
    gap: 10px;
}
