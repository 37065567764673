@import '../../../../styles/variables.scss';


.light{
    background-color: #fff;
    box-shadow: 1px 2px 2px #eee;
}

.dark{
    background: var(--tg-theme-bg-color);
    box-shadow: 1px 2px 2px var(--tg-theme-bg-color);
}

.bg{
    position: relative;
}

.checked{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255,255,255,0.8);
    &::before{
        position: absolute;
        left: -3px;
        top: 50%;
        transform: translateY(-50%);
        width: 5px;
        height: 90%;
        background: var(--tg-theme-button-color);
        border-radius: 3px 0 0 3px;
        content: '';
        display: block;
    }
}

.dark .checked{
    background: rgba(0,0,0,0.3);
}

.see{
    position: absolute;
    left: 0;
    top: 0;
    width: 5px;
    height: 100%;
    display: block;
    content: '';
    &::before{
        position: absolute;
        left: -3px;
        top: 50%;
        transform: translateY(-50%);
        width: 5px;
        height: 90%;
        background: #55bd91;
        border-radius: 3px 0 0 3px;
        content: '';
        display: block;
    }
}

.btn{
    background: var(--tg-theme-button-color);
    color: var(--tg-theme-button-text-color);
    border: none;
    outline: none;
    cursor: pointer;
    padding: 7px 10px;
    border-radius: 4px;
}