@import '../../../styles/variables.scss';

.container {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.group {
    text-align: center;
}

.text {
    margin-top: $gap-m;
    font-size: 18px;
}