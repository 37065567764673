@import '../../../styles/variables.scss';

.container {
    position: relative;
    z-index: 3;
    padding: 20px 0;
    background-color: #f2deea;
    color: #000;
    text-shadow: 0 0 #000;
    font-size: 18px;
    text-align: center;
    box-shadow: 0 0 3rem rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
}

.wrapper {
    max-width: $container-w;
    width: 100%;
}
