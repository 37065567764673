@import '../../styles/variables.scss';

.items {
    display: flex;
    flex-direction: row;
    row-gap: 40px;
    column-gap: 20px;
    flex-wrap: wrap;
    justify-content: center;

    @media (min-width: 836px) {
        justify-content: flex-start;
    }

}


.containerTop {
    margin-top: 50px;
}

.detail {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.date {
    color: #242729;
    @include fontStyle($font, 15px, 18px, 400);
}

.header {
    max-width: 772px;
    color: #000;
    @include fontStyle($font, 24px, 32px, 700);

    @media (min-width: 768px) {
        @include fontStyle($font, 36px, 48px, 700);
    }
}

.description {
    max-width: 772px;
    color: #000;
    @include fontStyle($font, 16px, 20px, 400);

    @media (min-width: 768px) {
        @include fontStyle($font, 18px, 24px, 400);
    }

    p {
        margin-bottom: 10px;
    }
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;

    @media (min-width: 1040px) {
        gap: 0;
        flex-direction: row;
        justify-content: space-between;
    }

}

.authors {
    position: relative;
}

.author {
    position: sticky;
    top: 10px;
    display: flex;
    flex-direction: row;
    gap: 30px;

    @media (min-width: 1040px) {
        justify-content: center;
    }

    &_ava {
        width: 65px;
    }

    &_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &__name {
        color: #242729;
        @include fontStyle($font, 18px, 24px, 400);

    }

    &__title {
        color: #242729;
        @include fontStyle($font, 15px, 18px, 400);
    }
}