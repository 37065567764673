@import '../../styles/variables.scss';

.container {
    margin-top: 0;
}

.btn {
    display: inline-block;
    padding: 0.35em 0.65em !important;
    height: auto !important;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem !important;
    text-align: center;
    color: $white;
    font-size: 0.95em !important;
    @extend .btnGreen;

    &_danger {
        display: inline-block;
        padding: 0.35em 0.65em !important;
        height: auto !important;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 0.25rem !important;
        text-align: center;
        color: $white;
        font-size: 0.95em !important;
        @extend .btnRed;
    }
}