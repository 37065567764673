@use "sass:math";
// ОТСТУПЫ
$gap-2xs: 4px;
$gap-xs: 8px;
$gap-s: 12px;
$gap-m: 16px;
$gap-l: 20px;
$gap-xl: 24px;
$gap-2xl: 32px;
$gap-3xl: 40px;
$gap-4xl: 48px;
$gap-5xl: 72px;

$gap-2xs-neg: -4px;
$gap-xs-neg: -8px;
$gap-s-neg: -12px;
$gap-m-neg: -16px;
$gap-l-neg: -20px;
$gap-xl-neg: -24px;
$gap-2xl-neg: -32px;
$gap-3xl-neg: -40px;
$gap-4xl-neg: -48px;
$gap-5xl-neg: -72px;

// BOX-SHADOW
$light-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
$header-shadow: 0 9px 17px -11px #9a9a9a;

// COLOR
$error-color: #f8908f;
$main-color: #2e2e2e;
$grin-color: #55bd91;
$dargen-color: #f8908f;
$bg-color: #f6f8fb;
$white: #fff;
$header-bg: #ffff;
$footer-bg: #f3f4f7;
$gray1: #e8eaeb;
$gray2: #a5a3a3;
$white: #fff;
$active: #3565b4;

$primary: #006bf6;
$warning-color: #fadc4b;

$price-completed: #10b981;
$price-error: #f43f5e;
$price-refund: #f59e0b;
$price-reserved: #0d63dc;

$font1: 'Raleway', sans-serif;
$font: 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

// FONT

// WIDTH
$container-w: 1248px;

// MQ VARS
$lg-width: 1199px;
$md-width: 991px;
$sm-width: 767px;

%boxShadow {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.08),
        0 4px 6px -2px rgba(0, 0, 0, 0.01);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
        0 4px 6px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
        var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.btnGreen {
    background: $grin-color !important;
    border-color: $grin-color !important;

    &:hover,
    &:focus {
        background: darken($grin-color, 10%) !important;
        border-color: darken($grin-color, 10%) !important;
    }
}

.btnRed {
    background: $dargen-color !important;
    border-color: $dargen-color !important;

    &:hover,
    &:focus {
        background: darken($dargen-color, 10%) !important;
        border-color: darken($dargen-color, 10%) !important;
    }
}

@mixin scrollbars(
    $size,
    $foreground-color,
    $background-color: mix($foreground-color, white, 50%)
) {
    //stylesheet for the display in Google Chrome
    &::-webkit-scrollbar {
        height: $size;
        width: $size;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: $foreground-color;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background: $background-color;
    }

    // stylesheet for the display in Internet Explorer
    & {
        scrollbar-track-color: $background-color;
        scrollbar-face-color: $foreground-color;
    }
}

@mixin before($top, $left, $right: auto, $bottom: auto) {
    content: '';
    position: absolute;
    top: $top;
    left: $left;
    right: $right;
    bottom: $bottom;
}

@mixin textPreview($max-height: 132px, $lines: 6) {
    display: block;
    display: -webkit-box;
    height: $max-height;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin fontStyle($font, $fontSize: 12px, $lineHeight: 1, $fontWeight: normal) {
    font-family: $font;
    font-size: $fontSize;
    line-height: math.div($lineHeight, $fontSize);
    font-weight: $fontWeight;
}

@mixin flex($horizontal, $vertical, $wrap) {
    display: flex;
    justify-content: $horizontal;
    align-items: $vertical;
    flex-wrap: $wrap;
}
