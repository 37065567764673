@import '../../styles/variables.scss';

.container {
    padding: $gap-l;
    border: 1px solid $gray1;
    border-radius: 7px;
    margin-bottom: $gap-l;
    background: #fff;
}

.navigate {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    border-top: 1px dashed #e9ebec;
    padding-top: 15px;
    margin-top: 20px;

    &_edit {
        display: flex;
        align-items: center;
        color: #878a99 !important;
        padding-left: 0 !important;

        svg {
            margin-right: 5px;
        }

        &:hover {
            color: $active  !important;
        }
    }
}

.name {
    margin-bottom: 0 !important;

    @media (max-width: 480px) {
        font-size: 1.7em !important;
    }

    &_content {
        display: flex;
        align-content: center;
        justify-content: space-between;
        align-items: flex-start;

        @media (max-width: 480px) {
            flex-direction: column;
            align-items: flex-end;
        }
    }
}

.body {
    white-space: pre-line;
}

.info {
    display: block;
    font-size: 12px;
    margin-bottom: $gap-2xs;
}

.clock {
    margin-right: $gap-xs;
}

.tags {
    margin: $gap-xs 0;
}

.tag {
    margin-bottom: $gap-xs  !important;
}

.status {
    display: inline-block;
    padding: 0.35em 0.65em;
    height: auto;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    text-align: center;
    color: $white;
    font-size: 0.95em;
}

.message {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.hr {
    width: 100%;
    height: 1px;
    background-color: $gray1;
    margin: $gap-m 0;
}

.author {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.authorImg {
    width: 42px;
    height: 42px;
    object-fit: cover;
    margin-right: $gap-m;
}

.button {
    margin-top: $gap-m;
    margin-left: auto;
    display: block !important;
}

.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.file {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    &_item {
        border-radius: 0.25rem;
        padding: 8px;
        border: 1px dashed #e9ebec;
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-right: 10px;
        margin-bottom: 8px;
    }

    &_img {
        background: #f3f6f9;
        padding: 5px;
        border-radius: 0.25rem;
        margin-right: 10px;

        img {
            width: 30px;
            max-width: 100%;
        }
    }

    &_name {
        color: #495057;
        font-weight: bold;
    }

    &_ico {
        color: #878a99;
        margin-left: 10px;
    }
}

.returnBack {
    color: #878a99 !important;
    padding-left: 0 !important;

    &:hover {
        color: $active  !important;
    }
}

.catogories {
    border-top: 1px dashed #e9ebec;
    border-bottom: 1px dashed #e9ebec;
    padding: 5px 0;
    margin: 10px 0;
    color: #878a99;
}

.blockTop {
    border-top: 1px dashed #e9ebec;
    padding-top: 15px;
    margin-top: 20px;
    color: #878a99;

    &_top {
        text-transform: uppercase;
        font-weight: 600;
        color: #878a99;
        margin-bottom: 4px;
    }
}

.panel {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

    @media (max-width: 480px) {
        grid-template-columns: repeat(1, minmax(100px, 1fr));
        grid-gap: 20px;
    }

    &_info {
        display: flex;
        flex-direction: column;
    }

    &_title {
        text-transform: uppercase;
        font-weight: 600;
        color: #878a99;
        margin-bottom: 4px;
    }

    &_desc {
        font-size: 0.9em;
        color: #495057;
    }
}