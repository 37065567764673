@import '../../styles/variables.scss';

.pagination {
    margin-top: $gap-l;
}

.table {
    max-width: 100%;
    overflow-x: scroll;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: $gap-l;
}

.link {
    color: #0ea5e9;
    font-weight: 500;
    cursor: pointer;

    &:hover {
        color: darken(#0ea5e9, 10%);
    }
}

.price {
    color: #10b981;
    font-weight: 500;
}

.ttitle {
    color: #0ea5e9;
    font-weight: 500;
    cursor: pointer;

    &:hover {
        color: darken(#0ea5e9, 10%);
    }
}

.tdate {
    font-size: 11px;
    color: #5e5e5e;
}

.noTender {
    color: rgb(219, 162, 56);
}

.account {
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    min-width: 220px;

    &_ava {
        cursor: pointer;
        margin-right: 7px !important;
    }

    &_name {
        width: calc(100% - 40px);
        cursor: pointer;
        font-size: 1em;

        &:hover {
            color: #0ea5e9;
        }
    }
}

.company {
    display: table;
    max-width: 400px;

    &_item {
        margin-bottom: 10px;
        display: table-row;
    }

    &_name {
        font-weight: bold;
        display: table-cell;
        min-width: 110px;
    }

    &_value {
        text-align: left;
        display: table-cell;
        font-weight: 300;
    }
}

.noOrder {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    background: #fff;
    border-radius: 4px;
    padding: 10vh 0;
    border: 1 solid rgba(4, 32, 69, 0.141);
    &__title {
        @include fontStyle($font, 20px, 36px, 400);
    }
}
