@import '../../styles/variables.scss';

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagination {
    margin-top: $gap-l;
}

.table {
    max-width: 100%;
    overflow-x: scroll;
}

.sort {
    display: flex;
}

.select{
    min-width: 200px;
}

.sortItem {
    & + &{
        margin-right: $gap-m;

    }
}