@import '../../../styles/variables.scss';

.wrap {
    background: #fff;
    display: table;
    table-layout: fixed;
    height: 100%;
    box-shadow: 0px 2px 5px -4px #777;
    width: calc(100% - 340px);

    @media (max-width: 767px) {
        position: absolute;
        width: calc(100% - 30px);
        margin-left: 15px;
        margin-right: 15px;
    }
}

.top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e9ebec;
    padding: 15px;

    &_info {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    &_setting {
        color: $gray2;
        cursor: pointer;
    }

    &__title {
        font-size: 1em;
        font-weight: 500;
    }

    &__size {
        font-size: 0.9em;
        color: $gray2;
    }
}

.messages {
    min-height: 100%;
    display: block;
}