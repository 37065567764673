@import '../../../styles/variables.scss';

.active {
    display: block !important;
}

.popop {
    position: relative;

    &_wrap {
        position: absolute;
        right: 0;
        bottom: 40px;
        z-index: 99;
        display: none;
    }
}

.icon {
    position: relative;
    cursor: pointer;
    color: #b3b3b3;
    transition: color 0.5s;
    &:hover {
        color: $active;
    }
}