@import '../../styles/variables.scss';

.formContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px 2.5rem 2.5rem 2.5rem;
}

.form {
    width: 100%;
}

.input {
    @media (min-width: 481px) {
        width: 50%;
    }
}

.flex {
    @media (min-width: 481px) {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 20px;
    }
}
