@import '../../styles/variables.scss';

.layout {
    margin-top: 3%;
    background: #fff;
    padding: 30px;

    &__container {
        width: 100%;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }
}

.body {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid #eee;
}

.step {
    padding: 20px;
    background: #fff;
    display: none;
}

.title {
    display: block;
    margin-bottom: 40px;
}

.select {
    margin-bottom: $gap-m !important;
}

.switch {
    display: block;
    margin-top: 40px;
    margin-bottom: 40px;

    &__title {
        cursor: pointer;
        margin-left: 10px;
    }
}

.bottomBlock {
    margin-top: 30px;
}

.recoveryPassword {
    display: inline-block;
    border-bottom: 1px dashed #3265CB;
    color: #3265CB;
    cursor: pointer;
}

.hidden {
    display: none !important;
}

.visible {
    display: block;
}

.authContainer {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.success {
    &_title {
        text-align: center;
        color: green !important;
    }

    &_body {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &__text {
        font-size: 1.3em;
    }
}

.button__mt30{
    margin-top: 30px;
}