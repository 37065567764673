* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

img {
    max-width: 100%;
}

body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
    width: 6px;
}
body::-webkit-scrollbar-track {
    background: transparent;
}
body::-webkit-scrollbar-thumb {
    background-color: rgb(52, 52, 52);
    border-radius: 20px;
    border: 2px solid rgb(52, 52, 52);
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
input {
    color: #2e2e2e;
}

a{
    text-decoration: none;
}

select {
    width: 100%;
}

div.ant-carousel .slick-list .slick-slide {
    pointer-events: auto;
}

.ant-pagination-options {
    display: none;
}

.ant-table-thead > tr > th {
    border-top: 1px solid #e2e8f0;
    border-bottom: 1px solid #e2e8f0;
    background-color: #f8fafc;
    color: #64748b;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1.3;
    text-transform: uppercase;
}

.ant-comment-avatar img {
    object-fit: cover;
}

.ant-select-item-option-content {
    white-space: initial;
}

.ant-form-item-label > label {
    font-weight: bold;
}

.textarea {
    resize: none;
    width: 100%;
    width: 100%;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    outline: none;
}

.textarea:focus,
.textarea:hover {
    border-color: #006bf6;
}

.textarea::-webkit-input-placeholder {
    color: #d9d9d9;
}

.textarea:-ms-input-placeholder {
    color: #d9d9d9;
}

.textarea::-moz-placeholder {
    color: #d9d9d9;
    opacity: 1;
}

.textarea:-moz-placeholder {
    color: #d9d9d9;
    opacity: 1;
}

.ant-tabs-top.ant-tabs-card .ant-tabs-nav {
    margin-bottom: 0;
}

.ant-tabs-top.ant-tabs-card .ant-tabs-content {
    background: #fff;
    padding: 20px;
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.08), 0 4px 6px -2px rgba(0, 0, 0, 0.01);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
        0 4px 6px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000),
        var(--tw-shadow);
    background-color: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 0.125rem;
    margin-bottom: 30px;
}

.emoji-picker-react:hover .emoji-scroll-wrapper::-webkit-scrollbar-thumb {
    background: #767676;
}

.emoji-picker-react .emoji-scroll-wrapper::-webkit-scrollbar {
    width: 6px;
}

.emoji-picker-react .emoji-scroll-wrapper::-webkit-scrollbar-track {
    background: transparent;
}

.emoji-picker-react .emoji-scroll-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: transparent;
}

.notification-notice-msg {
    color: #fff;
    background: rgba(0, 0, 0, 0.8) !important;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 2px #000;
    border-radius: 5px;
}

.notification-notice-msg .ant-notification-notice-message {
    color: #fff !important;
}

.notification-notice-msg .ant-notification-notice-close {
    color: rgba(255, 255, 255, 0.45);
}

.notification-notice-msg .ant-notification-notice-close:hover {
    color: rgba(255, 255, 255, 0.9);
}

.segmented .ant-segmented-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 480px) {
    .segmented .ant-segmented-item-label {
        font-size: 9px;
        line-height: 18px;
    }

    .ant-segmented.ant-segmented-block .ant-segmented-item {
        flex: 1 1 auto;
    }
}

.sp-title {
    font-size: 34px;
    font-weight: bold;
    margin-bottom: 30px;
    display: block;
    @supports (display: block) {
        @media screen and (max-width: 400px) {
           font-size: 24px;
        }
    }
}

.sp-text {
    font-size: 16px;
}
.sp-text--modif h3{
    margin-top: 30px;
    margin-bottom: 20px;
}
.sp-text--modif p{
    line-height: 1.6;
}

.sp-text--modif ul{
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
}

.sp-list {
    margin-left: 20px;
}

.sp-list li {
    margin-bottom: 10px;
}

.sp-list.number {
    list-style: auto;
}

.sp-list.circle {
    list-style: circle;
}

.sp-image-wrap{
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.max-w500{
    max-width: 500px;
    cursor: pointer;
}

.r-phone .react-tel-input .form-control {
    width: 100%;
    border-radius: 2px;
}

.tabs-score .ant-tabs-nav {
    margin-bottom: 0;
}

.tabs-score .ant-tabs-nav-wrap {
    padding-left: 20px;
}

.carousel .slick-list {
    padding: 30px 0 30px 10px;
}
.carousel .ant-carousel .slick-dots {
    position: relative;
    margin: 70px auto 0;
}
.carousel .ant-carousel .slick-dots li {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: linear-gradient(90deg, #ecdfdd 0%, #e3e2e9 91.53%);
}

.carousel .ant-carousel .slick-dots li.slick-active {
    background: #006bf6;
}
.carousel .ant-carousel .slick-dots li button {
    opacity: 0;
}

.reviewsSlider .swiper-wrapper {
    padding: 20px 0 20px 5px;
}
.reviewsSlider .swiper-pagination {
    position: relative;
    margin-top: 70px;
}
.reviewsSlider .swiper-pagination-bullet {
    width: 15px;
    height: 15px;
}


.hr-text{
    display: flex;
    align-items: center;
    margin: 2rem 0;
    font-size: .625rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: .04em;
    line-height: 1rem;
    color: #667382;
    height: 1px;
}
.hr-text:before {
    content: "";
    margin-right: 0.5rem;
    flex: 1 1 auto;
    height: 1px;
    background-color: #dadfe5;
}
.hr-text::after {
    content: "";
    margin-left: 0.5rem;
    flex: 1 1 auto;
    height: 1px;
    background-color: #dadfe5;
}