@import '../../../../styles/variables.scss';

.container{
    position: relative;
}

.loading{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,.8);
    display: flex;
    align-items: center;
    justify-content: center;
}

.info {
    color: #777777;
}

.tg_flex{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 10px 0;
}
.tg_link{
    color: #333;
    cursor: pointer;
}
.tg_tied{
    background: green;
    color: #fff;
    padding: 2px 8px;
    border-radius: 13px;
}
.tg_notTied{
    color: red;
    padding: 2px 8px;
    margin-bottom: 6px;
}