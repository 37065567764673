@import '../../../styles/variables.scss';

$title: #e9ebec;
$scroll_bg: #767676;
$gray: #bbb;

.wrap {
    display: table;
    width: 100%;
    table-layout: fixed;
    height: 100%;
    background: $white;
    box-shadow: 0px 2px 5px -4px #777;
    max-width: 340px;
    border-right: 1.5px solid $title;

    @media (max-width: 767px) {
        max-width: 100%;
        margin-left: 15px;
        margin-right: 15px;
        width: calc(100% - 30px);
    }

    @media (max-width: 480px) {
        width: 100%;
        margin: 0;
        padding-left: 15px;
        padding-right: 15px;
    }
}

.header {
    display: table;
    width: 100%;
    height: 73px;
    border-bottom: 1.5px solid $title;
}

.title {
    padding: 20px;
    font-weight: bold;
    font-size: 1.1em;
}

.body {
    display: table-row;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.list {
    position: relative;
    overflow: hidden;
    background: #fff;

    &:hover {
        &::-webkit-scrollbar-thumb {
            background: $scroll_bg;
        }
    }

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: transparent;
    }
}

.nomessage {
    color: $gray;
    background: $white;
    text-align: center;
    display: block;
}

.loading {
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    border-radius: 4px;
}

.emptyDialog {
    margin: 10vh 15px !important;
    display: block;
}

.emptyDesc {
    font-style: italic;
    color: $gray;
}