@import '../../../styles/variables.scss';

.wrap {
    display: flex;
    flex-direction: row;
    background: #fff;
    @extend %boxShadow;

    @media (max-width: 767px) {
        flex-direction: column;
    }

}

.left {
    flex-wrap: nowrap;
    min-width: 15rem;
    border-right: 1px solid #e2e8f0;
    padding: 1.5rem 1.25rem;

    @media (max-width: 767px) {
        border-bottom: 1px solid #e2e8f0;
        border-right: 0;
    }
}

.content {
    flex-grow: 1;
}

.title {
    padding: 1rem 1.25rem;
    margin-bottom: 0 !important;
}

.menu {
    list-style: none;
    margin-top: 10px;

    li {
        margin-bottom: 0.125rem;

        a {
            padding: 0.5rem 0.625rem;
            border-radius: 0.25rem;
            white-space: nowrap;
            display: block;

            span {
                color: #475569;
                font-weight: 500;
            }
        }
    }

    &__active {
        background-color: #eef2ff;

        span {
            color: $active;
        }
    }

    &__title {
        color: #94a3b8;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 0.75rem;
    }
}