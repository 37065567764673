@import '../../styles/variables.scss';

.button {
    display: flex;
    align-items: center;
    border: 1px dashed #d9d9d9;
    padding: 4px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.15s all ease-in-out;

    &:hover {
        border-color: #006bf6;
    }

    & > div {
        margin-left: 8px;
    }
}