@import '../../styles/variables.scss';

.subscribe {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 19px;
    width: 100%;
    min-height: 480px;
    padding: 15px 15px 40px;
    border-radius: 20px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    text-align: center;
    background-image: url('../../assets/subscribe-bg.jpg');

    @media (min-width: 620px){
        min-height: 356px;
    }
    
    @media (min-width: 992px){
        min-height: 450px;
    }

    &__h2 {
        color: #fff;
        @include fontStyle($font, 24px, 34px, 700);

        @media (min-width: 768px){
            @include fontStyle($font, 36px, 48px, 700);
        }
        
    }

    &__desc {
        position: relative;
        color: #ffffff;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);

        @include fontStyle($font, 15px, 19px, 400);

        @media (min-width: 768px){
            @include fontStyle($font, 18px, 24px, 400);
        }
        
    }
}

.form {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 1;
    border-radius: 20px;
    padding: 22px 15px;
    background: #ffffff;
    border-radius: 10px;
    margin-bottom: 80px;
    @media (min-width: 620px){
        max-width: 641px;
        margin: 0 auto;
    }


    &__input {
        width: 100%;
        @include fontStyle($font, 18px, 24px, 400);
        color: #242729;
        border: 0;
        outline: none;

        @media (min-width: 620px){
            padding-right: 200px;
        }
        

        &:focus,
        &:active,
        &:hover {
            outline: none;
        }
        &:auto-fill,
        &:-webkit-autofill,
        &:-webkit-autofill:focus {
            background: #fff !important;
        }
    }

    &__btn {
        @include before(90px, 50%);
        transform: translateX(-50%);
        cursor: pointer;
        @include fontStyle($font, 20px, 23px, 700);
        color: #ffffff;
        padding: 17px 28px;
        background: #006bf6;
        border-radius: 10px;
        transition: all 1s;
        border: 0;

        @media (min-width: 620px){
            @include before(5px, auto, 7px);
            transform: translateX(0);
        }

        &:hover {
            background: darken(#006bf6, 10%);
        }
    }
}
