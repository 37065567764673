@import '../../styles/variables.scss';

.container {
    width: 100%;
    // flex: 1 1 auto;
}

.title {
    text-align: left;
    font-family: $font1;
    font-weight: 700;
}

.carusel {
    margin: 10px 0;
    // overflow: hidden;
    width: 100%;
    // max-width: $container-w;

    .slick-dots {
        li {
            width: 15px;
            height: 15px;
            background: linear-gradient(90deg, #ecdfdd 0%, #e3e2e9 91.53%);
        }
    }
}

.SwiperSlide {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
        0px -4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 30px;
    width: 288px;

    @media (min-width: 480px){
        width: 386px;   
    }
    
    @media (min-width: 769px) {
        width: 426px;
    }

    @media (min-width: 1140px) {
        width: 495px;
    }
}

.review {
    &_item {
        display: flex;
        flex-direction: column;
    }

    &_author {
        margin-top: 28px;
    }

    &_txt {
        @include fontStyle($font1, 18px, 23px, 400);
        color: #000000;
    }
}

.author {
    display: flex;
    flex-direction: row;
    margin-top: 25px;

    &__ava {
        margin-right: 18px;
    }

    &_info {
        width: calc(100% - 51px - 18px);
        display: flex;
        flex-direction: column;
    }

    &__name {
        @include fontStyle($font1, 18px, 23px, 400);
        margin-bottom: 3px;
        color: #006BF6;
        display: inline-block;
        white-space: nowrap;
    }

    &__position {
        font-family: $font1;
        color: rgba(193, 193, 193, 1);
        font-weight: 600;
        font-size: 16px;
        line-height: 1.3;
    }
}

.button {
    margin-top: 55px;
    padding: 18px 20px;
    background: #2462d2;
    color: #fff;
    border: 0;
    outline: none;
    cursor: pointer;

    &:active,
    &:hover {
        background: darken(#2462d2, 10%);
    }
}