@import "../../styles/variables.scss";

:root {
    --companySize: 289px;
}

.company {
    display: flex;
    flex-direction: column;
    gap: 22px;
    width: 100%;
    @media (min-width: 992px){
        width: var(--companySize);
    }

    &__item {
        display: flex;
        flex-direction: column;
    }

    &_title {
        @include fontStyle($font, 24px, 32px, 700);
        color: #193656;
        margin-bottom: 16px;
    }

    &_val {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 8px;
        color: #193656;
        @include fontStyle($font, 24px, 32px, 700);


        span:nth-child(2) {
            margin-top: 3px;
            @include fontStyle($font, 18px, 24px, 400);
        }

        a {
            color: #193656;
            @include fontStyle($font, 16px, 19px, 400);
        }
    }
}

.fz16 {
    color: #193656;
    @include fontStyle($font, 16px, 19px, 400);
}

.flex {
    display: flex;
    flex-direction: column;
    gap: 30px;

    @media (min-width: 992px){
        flex-direction: row;
    }
    
}

.gap {
    gap: 30px;
}

.row {
    flex-direction: row;
}

.col {
    align-items: flex-start;
    flex-direction: column;
}

.contact {
    max-width: 700px;
    
    @media (min-width: 768px){
        padding-left: 20px;
    }
    
    @media (min-width: 992px){
        width: calc(100% - var(--companySize));
        padding-left: 123px;
    }
    
    &__row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    
        @media (max-width: 480px) {
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 10px;
        }
    
    }
    
    &__col {
        width: 100%;
    }
    &__title {
        text-align: center;
        color: rgb(52, 52, 52);
        display: block;
        font-size: 32px;
        font-weight: 700;
        letter-spacing: -0.4px;
        line-height: 40px;
        margin-bottom: 22px;
    }

    &__info {
        text-align: center;
        color: rgb(51, 53, 54);
        font-size: 16px font-weight 500;
        line-height: 24px;
    }

    &__button {
        align-items: center;
        background: $active;
        border-radius: 8px;
        color: #fff;
        cursor: pointer;
        display: block;
        font-size: 16px;
        font-weight: 400;
        height: 48px;
        line-height: 24px;
        padding: 11px 15px;
        text-align: center;
        width: 272px;
        margin: 0;
        border: 0;

        &:hover {
            background: lighten($active, 10%);
        }

        &:disabled {
            background: lighten($active, 30%);
        }
    }
}