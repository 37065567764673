@import '../../../styles/variables.scss';

.form {
    margin-bottom: $gap-2xl;
}

.title {
    &__info {
        color: green;
    }
}

.header {
    padding: 1.1em 1.3em;
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.content {
    padding: 1.1em 1.3em;
}