@import '../../../styles/variables.scss';

.form {
    margin-bottom: $gap-2xl;
}

.title {
    padding: 1.1em 1.3em;
    border-bottom: 1px solid #eee;
}

.content {
    padding: 1.1em 1.3em;
}

.editPass {
    display: flex;
    align-items: center;
    margin-bottom: 30px !important;
    cursor: pointer;

    &:hover {
        color: $active;
    }

    &>svg {
        margin-left: 15px;
    }
}

.alertNotConfirmed {
    margin-bottom: 20px !important;
}

.flexItems {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    @media (min-width: 580px) {
        flex-direction: row;
        gap: 20px;
    }

    &>div {
        @media (min-width: 580px) {
            width: calc(50% - 10px);
        }
    }
}

.info {
    color: #777777;
}