@import '../../styles/variables.scss';

.colorBlock {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #f0f4f9;
    border-radius: 15px;
    padding: 30px;
    gap: 24px;

    @media (min-width: 650px) {
        flex-direction: row;
        padding: 45px 50px;
    }

    @media (min-width: 992px) {
        padding: 45px;
    }

    @media (min-width: 1170px) {
        gap: 105px;
        padding: 45px 60px 45px 50px;
        align-items: center;
    }

    & + & {
        margin-top: 30px;
        @media (min-width: 1170px) {
            margin-top: 45px;
        }
    }
}

.textContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 24px;
    @media (min-width: 650px) {
        gap: 30px;
        width: 55%;
    }
    @media (min-width: 992px) {
        width: 50%;
    }
    @media (min-width: 1000px) {
        flex: 1 1 530px;
    }
}

.title {
    color: #193656;
    @include fontStyle($font, 24px, 32px, 700);
    @media (min-width: 1170px) {
        @include fontStyle($font, 36px, 47px, 700);
    }
}

.desc {
    color: #242729;
    @include fontStyle($font, 15px, 19px, 400);
    @media (min-width: 1000px) {
        @include fontStyle($font, 18px, 23px, 400);
    }
    @media (min-width: 1170px) {
        margin-top: 30px;
    }
}

.imageContent {
    width: 100%;
    max-width: 239px;
    @media (min-width: 768px) {
        width: 293px;
        max-width: none;
    }
    @media (min-width: 992px) {
        width: 406px;
    }
    @media (min-width: 1000px) {
        width: calc(100% - 465px);
    }
}

.defaultColor {
    background: #f0f4f9;
}

.orangeColor {
    background: linear-gradient(90deg, #f4f4e3 0%, #f0f4f9 100%);
}

.violetColor {
    background: linear-gradient(90deg, #f2efee 0%, #e6e3f4 91.53%);
}
