@import '../../../styles/variables.scss';

.body {
    padding: 20px;
    border: 1px solid #E8EAEB;
    border-radius: 7px;
    margin-bottom: 20px;
    background: #fff;

    @media (max-width: 480px) {
        padding: 15px;
        margin-left: 14px;
        margin-right: 14px;
    }
}

.title {
    margin-top: 10px;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 600;
    color: #878a99;
}

.desc {
    font-weight: 400;
    color: #83ace1;
    font-size: 1.3em;
    display: block;
    padding-bottom: 10px;
    margin-bottom: 6px;
    border-bottom: 1px dashed #e9ebec;
}

.info {
    display: flex;
    flex-direction: row;

    @media (max-width: 767px) {
        flex-direction: column;
    }

    &_account {
        flex: 1 1 auto;
        min-width: 160px;
        max-width: 210px;

        @media (max-width: 767px) {
            margin: 0 auto;
        }
    }

    &_company {
        flex: 0 0 35%;
        border-left: 1px dashed #e9ebec;
        padding: 10px;

        @media (max-width: 767px) {
            border-left: 0;
            flex: 1 1 auto;
        }

        @media (max-width: 480px) {
            padding: 0;
        }
    }

    &_contact {
        flex: 0 0 35%;
        border-left: 1px dashed #e9ebec;
        padding: 10px;

        @media (max-width: 767px) {
            border-left: 0;
            flex: 1 1 auto;
        }

        @media (max-width: 480px) {
            padding: 0;
        }
    }
}

.account {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;

    &__ava {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 65px;
        width: 65px;
        font-size: 16px;
        border-radius: 50% !important;
        padding: 0.25rem;
        background-color: #f3f6f9;
        border: 1px solid #e9ebec;
        border-radius: 0.25rem;
        max-width: 100%;
        cursor: pointer;

        img {
            border-radius: 50%;
            max-width: 55px;
        }
    }

    &__name {
        color: #495057;
        font-weight: bold;
        cursor: pointer;
    }

    &__role {
        color: #8a8a8a;
    }

    &__zakaz {
        color: #8a8a8a;
    }

    &__rating {
        margin-top: 1px;
        margin-bottom: 10px;
    }
}

.company {
    display: flex;
    flex-direction: column;

    &__item {
        display: flex;
        flex-direction: row;
        color: #8a8a8a;
        padding: 5px 0;
        // border-bottom: 1px dashed #e9ebec;
        font-size: 0.9em;

        span {
            font-size: 1.1em;
            margin-left: 10px;
            display: block;
            color: #495057;
            font-weight: bold;
        }
    }

    &__value {
        margin-bottom: 0 !important;
        margin-left: 10px;
        color: #495057;
        font-weight: bold;

        &>* {
            font-size: 1.1em;
        }
    }
}

.contact {
    display: flex;
    flex-direction: column;

    &__item {
        display: flex;
        flex-direction: row;
        color: #8a8a8a;
        padding: 7px 0;
        // border-bottom: 1px dashed #e9ebec;
        font-size: 0.9em;

        span {
            font-size: 1.1em;
            padding-left: 10px;
            color: #495057;
            font-weight: bold;
        }
    }

    &__value {
        margin-bottom: 0 !important;
        padding-left: 10px;
        color: #495057 !important;
        font-weight: bold;

        &>* {
            font-size: 1.1em;
        }
    }
}