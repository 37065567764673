@import "../../styles/variables.scss";

.container {
    padding: 60px $gap-m 50px;
    width: 100%;
    max-width: $container-w;
    margin-left: auto;
    margin-right: auto;
    flex: 1 1 auto;
}

.wrapExecutor {
    margin-top: 50px;

    .video_setting {
        margin-top: 0;
    }
}

.swiper{
    display: flex;
    flex-direction: column-reverse;
}

.video {
    &_btn_disable{
        opacity: 0.4;
    }
    &_slide{
        width: 370px;
        background: #f9f9f9;
        @media (max-width: 480px) {
            width: 280px;
        }
    }
    &_item {
        padding: 0;
        display: flex;
        flex-direction: column;
        // margin-right: 20px;
    }

    &_image {
        position: relative;
        width: 100%;
        height: 267px;
        background: #d9d9d9;
        display: block;
    }

    &__icon {
        font-size: 74px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50px);
        cursor: pointer;
    }

    &_body {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 34px 13px 20px;
    }

    &__position {
        position: absolute;
        left: 7px;
        top: -13px;
        text-transform: uppercase;
        font-family: $font1;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: #ffffff;
        padding: 7px;
        background: #ff7d44;
    }

    &__isp {
        background: #2462d2;
    }

    &__time {
        display: none !important;
        position: relative;
        font-family: "Calibri", $font1;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #006bf6;
        padding-left: 7px;

        &:before {
            position: absolute;
            left: 0;
            top: 0;
            width: 2px;
            height: 16px;
            background: #006bf6;
            content: "";
            display: block;
        }
    }

    &__name {
        margin-top: 16px;
        font-family: $font1;
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        color: #242729;
        cursor: pointer;

        &:hover {
            color: $active;
        }
    }

    &__text {
        font-family: $font1;
        margin-top: 45px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #242729;
    }

    &_setting {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 35px;
        // margin-top: -50px;

        @media (max-width: 480px) {
            margin-top: 15px;
            margin-bottom: 25px;
        }
    }

    &__previous {
        margin-right: 8px;
    }

    &__previous,
    &__next {
        cursor: pointer;
        width: 50px;
        height: 50px;
        background: #f5f5f5;
        color: #006bf6;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;

        @media (max-width: 480px) {
            font-size: 16px;
            width: 30px;
            height: 30px;
        }
    }
}

.author {
    display: flex;
    flex-direction: row;

    &__ava {
        margin-right: 18px;
    }

    &_info {
        width: calc(100% - 51px - 18px);
        padding-top: 7px;
        display: flex;
        flex-direction: column;
    }

    &__name {
        font-family: $font1;
        font-weight: 600;
        font-size: 16px;
        line-height: 1.2;
        margin-bottom: 3px;
        display: inline-block;
    }

    &__position {
        font-family: $font1;
        color: rgba(193, 193, 193, 1);
        font-weight: 600;
        font-size: 16px;
        line-height: 1.3;
    }
}

.button {
    margin-top: 55px;
    padding: 18px 20px;
    background: #2462d2;
    color: #fff;
    border: 0;
    outline: none;
    cursor: pointer;

    &:active,
    &:hover {
        background: darken(#2462d2, 10%);
    }
}