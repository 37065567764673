@import '../../../styles/variables.scss';

.body {
    width: 100%;
    display: flex;
    align-items: center;
    justify-self: center;
    flex-direction: column;
    padding: 2.3em 1.1em;
}

.header {
    font-size: 1.6em;
    font-weight: bold;
    margin-bottom: 20px;
}

.info {
    font-size: 1.2em;
    font-style: italic;
    margin-bottom: 50px;

    a {
        color: inherit;
    }
}

.img {
    max-width: 400px;
}