@import '../../../styles/variables.scss';

.title {
    padding: 1.1em 1.3em;
    margin-bottom: 0 !important;

    * {
        margin-bottom: 0 !important;
    }
}

.table {
    max-width: 100%;
    overflow-x: scroll;

    tbody tr {
        cursor: pointer;
    }
}

.pagination {
    margin: $gap-l;
}

.btn {
    padding: 4px!important;
}