@import '../../styles/variables.scss';

.container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    @media (max-width: $sm-width) {
        justify-content: center;
        text-align: center;
    }
}

.cart {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.08), 0 4px 6px -2px rgba(0, 0, 0, 0.01);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    background-color: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 4px;
}

.title {
    font-size: 1.8em;
    font-weight: bold;
}

.status_on {
    padding: 2px 12px;
    font-weight: bold;
    background: #66dd81;
    color: #fff;
    border-radius: 20px;
}

.profile {
    display: flex;
    padding: 20px;

    @media (max-width: 767px) {
        position: relative;
        flex-direction: column;
    }

    &__ava {
        flex: 0 0 auto;
        padding: 15px;

        @media (max-width: 767px) {
            padding: 15px 0 0;
        }

        @media (max-width: 450px) {
            text-align: center;
        }
    }

    &__status {
        display: flex;
        margin: 10px 0;
        justify-content: center;

        @media (max-width: 767px) {
            justify-content: flex-start;
        }

        @media (max-width: 450px) {
            justify-content: center;
        }
    }

    &__content {
        flex: 1 1 auto;
        padding: 25px 15px 15px;

        @media (max-width: 767px) {
            padding: 0;
        }
    }

    &__contact {
        display: flex;
        align-content: center;
    }

    &__name {
        font-size: 1.4em;
    }

    &__valid {
        margin-left: 20px;
    }

    &__desc {
        margin-top: 20px;
        line-height: 1.8;
        color: rgb(70, 70, 70);
        white-space: pre;
    }
}

.setting {
    flex: 0 0 auto;
    padding: 15px;

    @media (max-width: 767px) {
        position: absolute;
        right: 0;
        top: -35px;
    }

    &__item {
        display: flex;
        align-items: center;
        padding: 13px;
        background: #fff;
        box-shadow: 1px 2px 2px 1px #cbcbcb;
        border-radius: 50%;
        cursor: pointer;
    }
}

.info {
    display: flex;
    margin-top: 5px;

    &_item {
        color: #7b7b7b;

        &+& {
            margin-left: 10px;
        }
    }
}

.process {
    width: 100%;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    margin-top: 20px;
    padding: 30px 20px;
    background: #fbfdff;
    border-top: 1px solid #f3f4f9;
    border-bottom: 1px solid #f3f4f9;

    @media (min-width: 768px) and (max-width: 991px) {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }

    @media (max-width: 767px) {
        grid-template-columns: 1fr;
    }


    &__item {
        display: flex;
        flex-direction: row;
        text-align: center;

        @media (min-width: 768px) and (max-width: 991px) {
            align-items: center;
            flex-direction: column;
        }

        @media (max-width: 767px) {
            text-align: left;
        }
    }

    &__img {
        padding: 15px 18px;
        background: #eee;
        margin-right: 20px;
        border-radius: 50%;
        display: flex;

        @media (min-width: 768px) and (max-width: 991px) {
            margin-right: 0;
            margin-bottom: 10px;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
    }

    &__count {
        color: #5a606c;
        font-weight: bold;
        font-size: 1.6em;
    }

    &__title {
        font-size: 1.1em;
        color: #b7bbc8;
    }
}

.color1 {
    background: #d9e7fc;

    &>svg {
        color: darken(#d9e7fc, 20%);
    }
}

.color2 {
    background: #fcead3;

    &>svg {
        color: darken(#fcead3, 20%);
    }
}

.color3 {
    background: #fae2e3;

    &>svg {
        color: darken(#fae2e3, 20%);
    }
}

.color4 {
    background: #ddf6d9;

    &>svg {
        color: darken(#ddf6d9, 20%);
    }
}

.color5 {
    background: #e2dafc;

    &>svg {
        color: darken(#e2dafc, 20%);
    }
}

.details {
    margin-top: 30px;
    padding: 20px 30px;
}