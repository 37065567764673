@import "../../styles/variables.scss";

.whatgets {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;

    @media (min-width: 600px) {
        flex-direction: row;
    }

}

.textInformation {
    color: #242729;
    @include fontStyle($font, 18px, 24px, 400);
    max-width: 963px;

    @media (max-width: 991px) {
        font-size: 16px;
        line-height: 20px;
    }

    ul {
        padding-left: 40px;
    }
}

.ulTitle {
    margin-bottom: 0;
    color: #242729;
    @include fontStyle($font, 18px, 24px, 400);
    max-width: 963px;

    @media (max-width: 991px) {
        font-size: 16px;
        line-height: 20px;
    }
}

.bg {
    background: #f5f5f5;
}

.row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-left: $gap-m-neg;
    margin-right: $gap-m-neg;

    @media (max-width: $sm-width) {
        flex-direction: column-reverse;
    }
}

.grid {
    width: 100%;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));

    @media (max-width: 480px) {
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }

}

.container {
    width: 100%;
    max-width: $container-w;
    margin-left: auto;
    margin-right: auto;
    padding: $gap-4xl $gap-m;
    flex: 1 1 auto;
}

.pb0 {
    padding-bottom: 0;
}

.process {
    text-align: center;

    &:hover &_count {
        box-shadow: 0px 0px 0px 7px lighten($primary, 25%);
    }

    &_grid {
        width: 100%;
        display: grid;
        grid-gap: 30px;
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
        margin-bottom: 40px;
    }

    &_count {
        background: $primary;
        color: #fff;
        width: 74px;
        height: 74px;
        line-height: 74px;
        border-radius: 50%;
        margin: 0 auto;
        transition: all 0.5s ease;
        font-size: 1.4em;
        font-weight: bold;
    }

    &_bottom {
        position: relative;
        background-color: #f9f9f9;
        margin-top: 1.8rem;
        padding: 1.4rem;
        height: calc(100% - 95px);

        &:before {
            content: "";
            position: absolute;
            width: 28px;
            height: 28px;
            background-color: #f9f9f9;
            transform: rotate(45deg);
            left: 0;
            right: 0;
            margin: 0px auto;
            margin-top: -35px;
        }
    }

    &_title {
        font-size: 19px;
        font-weight: bold;
    }

    &_desc {
        margin-top: 1.3em;
        color: #8492a6;
        font-size: 16px;
        line-height: 24px;
    }
}