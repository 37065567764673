@import "../../../../styles/variables.scss";

.container {
    display: inline-block;
}

.open {
    color: $active;
    display: flex;
}

.close {
    position: absolute;
    top: 10px;
    right: 30px;
    color: #fff;
}

.logo {
    margin: 0 auto 30px;
    display: table;
}

.menu {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: #006BF6;
    color: #fff;
    padding: 60px 15px 40px;
    max-width: 400px;
    display: none;
    overflow-y: scroll;

    &__open {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        animation: showMenu 0.5s ease-in;
    }
}

.navigation {
    margin: 0;
    padding: 0;
    list-style: none;
    max-width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    li {
        padding: 15px 0;
        width: 100%;
    }

    li:not(:last-child) {
        border-bottom: 1px solid #FFFFFF;
    }

    a {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #FFFFFF;
    }

    a.icon {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }
}

@keyframes showMenu {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}