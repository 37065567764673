@import '../../../styles/variables.scss';

.title {
    padding: 1.1em 1.3em;
    margin-bottom: 0 !important;

    * {
        margin-bottom: 0 !important;
    }
}

.table {
    max-width: 100%;
    overflow-x: scroll;

    tbody tr {
        cursor: pointer;
    }
}

.tableRow {
    cursor: pointer;
}

.pagination {
    margin: $gap-l;
}

.btn {
    padding: 4px !important;
}


.filter {
    padding: 0.2em 1.3em 1.1em 1.3em;
}

.add {
    color: $price-completed;
    background-color: lighten($price-completed, 55%);
    border-radius: 20px;
    font-weight: 500;
    padding: 0.125rem 0.625rem;
    display: table;
    margin: 0 auto;
}

.writeDowns {
    color: $price-error;
    background-color: lighten($price-error, 35%);
    border-radius: 20px;
    font-weight: 500;
    padding: 0.125rem 0.625rem;
    display: table;
    margin: 0 auto;
}

.refund {
    color: $price-refund;
    background-color: lighten($price-refund, 45%);
    border-radius: 20px;
    font-weight: 500;
    padding: 0.125rem 0.625rem;
    display: table;
    margin: 0 auto;
}

.reserved {
    color: $price-reserved;
    background-color: lighten($price-reserved, 45%);
    border-radius: 20px;
    font-weight: 500;
    padding: 0.125rem 0.625rem;
    display: table;
    margin: 0 auto;
}

.pagination {
    margin: $gap-l $gap-l $gap-l auto;
    display: table;
}

.account {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &_name {
        padding-left: 10px;
        width: 140px;
    }
}

.flex {
    display: flex;
    flex-direction: row;
}

.col {
    width: 50%;
}