@import '../../styles/variables.scss';

.empty {
    min-height: 240px;
}

.notification {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: 10px;
    z-index: 1;

    &_wrap {
        position: relative;
    }
}

.waiting {
    background: #e9ae55;
    color: #fff;
}

.enabled {
    background: #2dd48f;
    color: #fff;
}

.errMessage {
    color: rgb(211, 62, 62);
    padding-bottom: 10px;
}

.loading {
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    border-radius: 4px;
}

.messageBodyList {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    min-height: 300px;
    max-height: 400px;
    background-image: radial-gradient(#c2c2c2 1.5px, transparent 1.5px);
    background-size: 42px 42px;
    background-color: #efefef;

    &:hover {
        &::-webkit-scrollbar-thumb {
            background: #767676;
        }
    }

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: transparent;
    }
}

.wrap {
    position: sticky;
    top: 0;
    left: 0;
    display: block;
    border-radius: 5px;
    padding: 0;
    margin-bottom: $gap-l;
    background: #fff;
    box-shadow: 9px 8px 10px -11px rgba(34, 60, 80, 0.47);
    // box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
}

.chat_head {
    padding: 15px;
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__title {
        font-size: 1.2em;
        font-weight: bold;
    }
}

.ico_on {
    color: $active;
    cursor: pointer;
}

.ico_off {
    color: #777;
    cursor: pointer;
}

.nomessage {
    color: #bbb;
    text-align: center;
    display: block;
}

.message {
    padding: 20px;
    border-top: 1px solid #eee;
    background: #fff;
}

.messageInput {
    margin-bottom: 10px;
}

.messageButtom {
    text-align: right;
}

.chat {
    &_body {
        padding: 10px 15px;
    }
}

.avaWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}

.ava{
    max-width: 40px;
}

.chat_right {
    &__avatar {
        cursor: pointer;
        float: right;
        box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
        border-radius: 50%;
    }

    &__body {
        margin: 5px 40px 0 0;
        padding-right: 10px;
        display: block;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    &__body:hover &__time {
        opacity: 1;
    }

    &__time {
        opacity: 0;
        margin-top: 4px;
        font-size: 0.7em;
    }

    &__content {
        background-image: linear-gradient(80deg, #006cf6, #0962d4);
        padding: 0.7rem 1rem;
        clear: both;
        background-repeat: repeat-x;
        border-radius: 0.357rem;
        box-shadow: 0 4px 8px 0 rgba(34, 41, 47, 0.12);
        color: #fff;
        white-space: pre-line;

        &>p {
            margin: 0;
            color: inherit;
        }
    }
}

.chat_left {
    &__avatar {
        float: left;
        cursor: pointer;
        box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
        border-radius: 50%;
    }

    &__body {
        margin: 5px 40px 0 0;
        padding-left: 10px;
        display: block;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__body:hover &__time {
        opacity: 1;
    }

    &__read {
        position: absolute;
        right: -5px;
        bottom: -5px;
        background: red;
        padding: 3px;
        border-radius: 50%;
        content: '';
        display: block;
    }

    &__time {
        opacity: 0;
        margin-top: 4px;
        font-size: 0.7em;
    }

    &__content {
        position: relative;
        float: left;
        // margin: 0 0 10px 1rem;
        padding: .7rem 1rem;
        background: #55bd91;
        clear: both;
        background-repeat: repeat-x;
        border-radius: 0.357rem;
        box-shadow: 0 4px 8px 0 rgba(34, 41, 47, 0.12);
        color: #fff;
        white-space: pre-line;

        &>p {
            margin: 0;
            color: inherit;
        }
    }
}