@import '../../../styles/variables.scss';

.bottomBlock {
    margin-top: $gap-s;
    margin-left: $gap-m-neg;
}

.link {
    margin-right: $gap-s;
}
.social{
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
}

.container{
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--tg-theme-bg-color);
    min-height: calc(100vh - 120px);
}


.icon_tg{
    position: relative;
    top: 2px;
}