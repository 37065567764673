@import '../../../styles/variables.scss';

.content {
    border-top: 1px dashed #eee;
    padding: 1.1em 1.3em;
}

.infoBlok {
    padding: 15px 20px;
    background-color: #f6f6f6;
    border-bottom: 1px solid #d7d7d7;
    display: flex;
    align-items: center;

    &_img {
        width: 30px;
        margin-right: 20px;
    }
}

.infoPrice {
    display: flex;
    align-items: center;
}

.info {
    width: 100%;

    dl {
        display: flex;
        margin-bottom: 0;
    }

    dt {
        margin-right: 10px;
    }
}