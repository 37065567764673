@import '../../styles/variables.scss';

$color: #333536;
$link: #a6a8a9;

.footer {
    background-color: $footer-bg;
    border-top: 1px solid #eee;
    color: $color;
    font-weight: 400;

    &__top {
        background-color: $footer-bg;
        margin-top: 30px;
    }

    &__bottom {
        padding: 10px 0;
        background-color: #eaebef;
    }

    &__row {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &__mobile {
            @media (max-width: 992px) {
                flex-direction: column;
                align-items: center;
            }
        }
    }
}

.logo {
    max-width: 200px;
    margin-bottom: 15px;
}

.row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;

    @media (max-width: 480px) {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10px;
    }

}

.col {
    width: 100%;
}


.content {
    width: 100%;
    max-width: $container-w;
    margin-left: auto;
    margin-right: auto;
    padding: $gap-xs $gap-m;
    flex: 1 1 auto;
}

.menu_title {
    white-space: nowrap;
    font-size: 18px;
    line-height: 1.2222222;
    font-weight: 700;
    margin-bottom: 0.33333em;
}

.menu {
    list-style: none;

    li {
        padding: 0;
        margin: 0;
    }

    a {
        color: #333;
        font-weight: inherit;
        transition: color .2s ease-in-out;
        outline: none;
        font-size: 14px;

        &:hover {
            color: $active;
        }
    }
}

.menu_list {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0;

    @media (max-width: 540px) {
        flex-direction: column;
    }

    li {
        &:first-child {
            margin-left: 0;
        }

        margin-left: 10px;

        @media (max-width: 540px) {
            margin-left: 0;
            margin-bottom: 5px;
        }
    }

    a {
        color: $link;
        transition: color .2s ease-in-out;

        &:hover {
            color: darken($link, 20%);
        }
    }
}

.blok {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;

    @media (max-width: 991px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 540px) {
        grid-template-columns: repeat(1, 1fr);
    }

    &>div:nth-child(1) {
        @media (max-width: 991px) {
            order: 3
        }
    }

    &>div:nth-child(2) {
        @media (max-width: 991px) {
            order: 1
        }
    }

    &>div:nth-child(3) {
        @media (max-width: 991px) {
            order: 2
        }
    }

    &>div:nth-child(4) {
        @media (max-width: 991px) {
            order: 4
        }
    }

}

.phone {
    color: $active;
    font-weight: bold;
    transition: color .2s ease-in-out;
    outline: none;
    white-space: nowrap;
    font-size: 18px;
    line-height: 1.2222222;
}

.email {
    color: #343434;
    font-weight: bold;
    transition: color .2s ease-in-out;
    outline: none;
    white-space: nowrap;
    font-size: 15px;
    line-height: 1.2222222;
}

.desc {
    color: #7c7e80;
}

.button {
    color: #343434;
    border: 1px solid #e7e9eb;
    padding: 5px 10px;
    background: #fff;
    border-radius: 8px;
    margin: 5px 0;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        background: transparent;
    }

    svg {
        margin-right: 7px;
        color: #343434;
    }
}

.modal {
    &_title {
        text-align: center;
        color: rgb(52, 52, 52);
        display: block;
        font-size: 32px;
        font-weight: 700;
        letter-spacing: -0.4px;
        line-height: 40px;
        margin-bottom: 22px;
    }

    &_info {
        text-align: center;
        color: rgb(51, 53, 54);
        font-size: 16px font-weight 500;
        line-height: 24px;
    }

    &_button {
        align-items: center;
        background: $active;
        border-radius: 8px;
        color: #fff;
        cursor: pointer;
        display: block;
        font-size: 16px;
        font-weight: 400;
        height: 48px;
        line-height: 24px;
        padding: 11px 15px;
        text-align: center;
        width: 272px;
        margin: 0;
        border: 0;

        &:hover {
            background: lighten($active, 10%);
        }

        &:disabled {
            background: lighten($active, 30%);
        }
    }
}

.social {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin-bottom: 0;

    li {
        &:first-child {
            margin-left: 0;
        }

        margin-left: 7px;
    }
}