@import '../../styles/variables.scss';

.container {
    margin-top: 40px;
    padding: $gap-xs 0 $gap-l;
    border-radius: 7px;

    // border: 1px solid $gray1;
    // background: #fff;
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
        padding-right: 10px;

        @media (max-width: 540px) {
            flex-direction: column;
        }
    }

    &__offer {
        display: block;
        margin-bottom: 40px;
        padding-right: 10px;
    }
}

.crowns {
    position: absolute;
    top: -24px;
    left: -17px;
    width: 40px;
    max-width: 100%;
    transform: rotate(-30deg);
    cursor: help;
}

.newCom {
    position: absolute;
    left: -3px;
    top: -3px;
    width: 10px;
    height: 10px;
    background: #ff8c00;
    border-radius: 50%;
    content: '';
    display: block;
}

.wrapNoTender {
    padding: 20px;
    border: 1px solid #E8EAEB;
    border-radius: 7px;
    margin-bottom: 20px;
    background: #fff;
}

.btn {
    background: #55bd91 !important;
    border-color: #55bd91 !important;
    box-shadow: 0 2px 0 10px rgba(0, 0, 0, 0.05) !important;
    border-radius: 0 !important;

    &:hover,
    &:focus {
        background: darken(#55bd91, 10%) !important;
        border-color: darken(#55bd91, 10%) !important;
    }

    .anticon {
        font-size: 12px;
    }

    &_withdraw {
        background: #e96055 !important;
        border-color: #e96055 !important;
        // box-shadow: 0 2px 0 10px rgba(0, 0, 0, 0.05) !important;
        border-radius: 0 !important;

        &:hover,
        &:focus {
            background: darken(#e96055, 10%) !important;
            border-color: darken(#e96055, 10%) !important;
        }
    }
}

.pagination {
    margin-top: $gap-l;
}

.title {
    margin-bottom: $gap-l;
}

.title_offer {
    margin-bottom: $gap-3xl !important;
}

.item {
    margin-bottom: 1.5rem;
    ;
}

.text {
    font-size: 14px;
}

.author {
    font-size: 14px;
    cursor: pointer;
}

.agree {
    font-size: 14px !important;
    background: #55bd91 !important;
    border-color: #55bd91 !important;
    padding: 7px !important;
    height: auto !important;

    &:hover,
    &:focus {
        background: darken(#55bd91, 10%) !important;
        border-color: darken(#55bd91, 10%) !important;
    }
}

.win {
    display: flex;
    align-items: flex-start;
    cursor: pointer;
    border-top: 1px solid $gray1;
    padding-top: $gap-s;
}

.winInfo {
    display: flex;
    flex-direction: column;
}

.avatar {
    cursor: pointer;
    height: 45px;
    width: 45px;
    font-size: 16px;
    border-radius: 50% !important;
    padding: 0.25rem;
    background-color: #f3f6f9;
    border: 1px solid #e9ebec;
    border-radius: 0.25rem;
    max-width: 100%;

    img {
        border-radius: 50%;
        max-width: 35px;
    }
}

.winText {
    margin-bottom: $gap-2xs;
}

.comment {
    display: flex;
    position: relative;
    padding: 1rem;
    box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15);
    border-radius: 0.25rem;
    background: linear-gradient(to bottom, #ffffff 0%, #ffffff 80%, #f8f8f8 100%);

    &__first {
        box-shadow: 0 1px 2px rgba(235, 168, 45, 0.65);
    }

    &__notRelevant {
        background: #f6f6f6;
    }

    &_footer {
        position: absolute;
        padding: 10px;
        right: 0px;
        bottom: 0;
    }

    &_block+&_block {
        margin-top: 20px;
    }

    &_block {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 15px;
        width: 100%;

        @media (max-width: 540px) {
            grid-template-columns: 1fr;
        }


        &__btn {
            grid-template-columns: 1fr 1fr auto;

            @media (max-width: 540px) {
                grid-template-columns: 1fr;
            }
        }
    }

    &_info {
        display: flex;
        width: 100%;
        flex-direction: column;
    }

    &__edit {
        position: absolute;
        right: 10px;
        bottom: 10px;
        cursor: pointer;

        &:hover {
            color: $active;
        }
    }

    &_wrap {
        padding: 1rem 1rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: nowrap;
        width: 100%;

        @media (min-width: 992px) and (max-width: 1070px) {
            flex-direction: column;
        }

        @media (max-width: 767px) {
            flex-direction: column;
        }

    }

    &_item {
        min-width: 100px;
    }

    &_message {
        min-width: 100%;
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 0.5px dashed #d9d9d9;
    }

    &_btn {
        flex: 0 0 auto;
        width: auto;
    }

    &__desc {
        margin-top: 5px;
        color: #878a99;
        font-size: 12px;
    }

    &__txt {
        font-weight: bold;
        color: #495057;
    }
}

.profile {
    flex: 1 1 auto;
    min-width: 200px;
    max-width: 250px;

    &_wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    &_ava {
        margin-bottom: 10px;
    }

    &_info {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &_name {
        color: #495057;
        font-weight: bold;
    }

    &_type {
        color: #8a8a8a;
    }

    &_sizeorder {
        color: #8a8a8a;
    }

    &_rate {
        margin-top: 1px;
        margin-bottom: 10px;
    }

    &_time {
        color: #878a99;
    }
}