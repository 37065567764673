@import '../../../../styles/variables.scss';


.order{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 20px;
    background: var(--tg-theme-bg-color);
    &_title{
        color: var(--tg-theme-text-color);
        font-weight: 600;
        line-height: 1.4;
        font-size: 1.4em;
    }
    &_categories{
        padding: 5px 0;
        margin: 10px 0;
        color: var(--tg-theme-text-color);
        span{
            font-style: italic;
        }
    }
    &_desc{
        padding: 5px 0;
        white-space: pre-line;
    }
    &_footer{
        padding: 5px 0;
    }
}

.info{
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    gap: 10px;
    &__title{
        color: var(--tg-theme-text-color);
        font-weight: 600;
        line-height: 1.4;
        font-size: 1.2em;
        margin-bottom: 10px;
    }
    &__text{
        color: var(--tg-theme-text-color);
        font-weight: 600;
        line-height: 1.4;
        font-size: 1.1em;
    }
    &__item{
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
}

.status {
    display: inline-block;
    padding: 0.15em 0.65em;
    height: auto;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
    text-align: center;
    color: $white;
    font-size: 0.95em;
}


.blockTop {
    border-top: 1px dashed #e9ebec;
    padding-top: 15px;
    margin-top: 20px;
    color: #878a99;

    &_top {
        color: var(--tg-theme-text-color);
        font-weight: 600;
        line-height: 1.4;
        font-size: 1.2em;
        margin-bottom: 4px;
    }
}


.file {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    &_item {
        border-radius: 0.25rem;
        padding: 4px;
        border: 1px dashed #e9ebec;
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-right: 7px;
        margin-bottom: 5px;
    }

    &_img {
        background: #f3f6f9;
        padding: 5px;
        border-radius: 0.25rem;
        margin-right: 10px;

        img {
            width: 20px;
            max-width: 100%;
        }
    }

    &_name {
        color: var(--tg-theme-text-color);
        font-weight: bold;
    }

    &_ico {
        color: var(--tg-theme-text-color);
        margin-left: 7px;
    }
}