@import '../../../styles/variables.scss';

.pagination {
    margin-top: $gap-l;
}

.grid {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));

    @media (max-width: 767px) {
        grid-template-columns: 1fr;
    }
}

.review {
    &__item {
        display: flex;
        flex-direction: column;
    }

    &__body {
        margin-top: 10px;
        margin-bottom: 10px;
        color: #5e6278;
        font-size: 1em;
        line-height: 1.8;
        font-weight: 400;
    }
}

.account {
    display: flex;
    flex-direction: row;

    &__ava {
        margin-right: 15px;

        &>span {
            border-radius: 4px;
        }
        img{
            max-width: 45px;
        }
    }

    &__info {
        flex: 1;
        flex-direction: column;
    }

    &__top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &__name {
        font-size: 1.2em;
        font-weight: 500;
        color: #181c32;
    }

    &__time {
        font-size: 0.8em;
        color: #a1a5b7;
    }

    &__rating {
        margin-top: 0;
        font-size: 14px !important;
    }
}