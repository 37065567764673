@import '../../../../styles/variables.scss';

.wrap {
    display: table-row;
    width: 100%;
    height: 63px;
}

.footer {
    border-top: 1px solid #e9ebec;
    padding: 15px 50px;
    background: #fafbff;
    display: block;

    @media (max-width: 767px) {
        padding: 15px;
    }
}

.errMessage {
    color: rgb(211, 62, 62);
    padding-bottom: 10px;
}

.top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;

    div {
        font-weight: 500;
    }
}

.refund {
    cursor: pointer;
    color: #26599b;
}

.msgBox {
    position: relative;
    display: flex;
    justify-content: space-between;

    &__send {
        position: absolute;
        bottom: 1px;
        right: -40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 14px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-image: linear-gradient(80deg, #006cf6, #0962d4);
        color: #fff;
        cursor: pointer;
        animation: visible 0.6s;
    }

    &__picker {
        position: absolute;
        right: 10px;
        bottom: -2px;
    }

    &__textarea {
        border-radius: 6px !important;
    }
}

.messageButtom {
    text-align: right;
}

@keyframes visible {
    0% {
        transform: scale(0);
        opacity: 0;
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}