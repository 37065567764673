@import '../../../styles/variables.scss';

.add {
    color: $price-completed;
    background-color: lighten($price-completed, 55%);
    border-radius: 20px;
    font-weight: 500;
    padding: 0.125rem 0.625rem;
    display: table;
    margin: 0 auto;
}

.writeDowns {
    color: $price-error;
    background-color: lighten($price-error, 35%);
    border-radius: 20px;
    font-weight: 500;
    padding: 0.125rem 0.625rem;
    display: table;
    margin: 0 auto;
}

.refund {
    color: $price-refund;
    background-color: lighten($price-refund, 45%);
    border-radius: 20px;
    font-weight: 500;
    padding: 0.125rem 0.625rem;
    display: table;
    margin: 0 auto;
}

.reserved {
    color: $price-reserved;
    background-color: lighten($price-reserved, 45%);
    border-radius: 20px;
    font-weight: 500;
    padding: 0.125rem 0.625rem;
    display: table;
    margin: 0 auto;
}

.drawer {
    height: 100%;
    filter: drop-shadow(0 10px 8px rgba(0, 0, 0, 0.04)) drop-shadow(0 4px 3px rgba(0, 0, 0, 0.1));

    &_title {
        font-weight: bold;
        font-size: 1.3em;
        text-align: center;
    }

    &_time {
        font-style: italic;
        text-align: center;
    }

    &_footer {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
    }

    &_spin {
        margin: calc(50% - 20px) auto !important;
        display: table !important;
    }
}

.score {
    margin-top: 40px;

    &_top {
        background-color: #ffffff;
        padding: 15px;
        text-align: center;
    }

    &_footer {
        background-color: #ffffff;
        padding: 20px 15px 20px;
    }

    &_middle {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    &_circle {
        fill: #ffffff;
        width: 1.25rem;
        height: 1.25rem;
        display: block;

        &__reserve {
            transform: rotate(180deg);
        }
    }

    &_line {
        position: relative;
        background-color: #ffffff;
        width: 100%;
        height: 20px;
        display: block;

        &::before {
            position: absolute;
            top: 10px;
            border-top: 1px dashed #eaeaea;
            width: 100%;
            height: 1px;
            content: '';
            display: block;
        }
    }

    &_img {
        position: relative;
        margin: -42px auto 25px;
        background: #ffffff;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &_price {
        font-size: 1.7em;
        font-weight: bold;
    }

    &_status {
        margin-top: 20px;
        font-size: 1em;
    }
}

.info {
    display: flex;
    flex-direction: column;

    &_icol {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
    }

    &_irow {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }

    &_name {
        width: 120px;
        font-weight: bold;
        font-style: italic;
        color: #535353;
        padding-right: 5px;
    }

    &_value {
        width: calc(100% - 125px);
        text-align: right;
    }

    &_history {
        font-weight: bold;
        font-style: italic;
        color: #535353;
        padding-bottom: 10px;

        &_list {
            padding-left: 20px;
        }
    }
}

.price {
    padding: 1.1em 1.3em;

    &_val {
        font-weight: bold;
        font-size: 1.5em;

        span {
            color: $price-completed;
        }
    }
}

.account {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &_name {
        padding-left: 10px;
        width: 140px;
    }
}