@import '../../styles/variables.scss';

.theme-light {
    --tg-theme-bg-color: #fff;
    --tg-theme-text-color:#333333;
    --tg-theme-hint-color:#8C8C8C;
    --tg-theme-link-color:#0088CC;
    --tg-theme-button-color:#30A3E6;
    --tg-theme-button-text-color:#fff;
    --tg-theme-secondary-bg-color:#bebebe;
    --tg-theme-input-bg-color:#f0f0f0;
    --tg-theme-input-text-color:#333333;
    --tg-theme-body-color: #f0f0f0;
  }

  .theme-dark {
    --tg-theme-bg-color: #17212B;
    --tg-theme-text-color:#fff;
    --tg-theme-hint-color:#eee;
    --tg-theme-link-color:#0088CC;
    --tg-theme-button-color:#30A3E6;
    --tg-theme-button-text-color:#fff;
    --tg-theme-secondary-bg-color:#bebebe;
    --tg-theme-input-bg-color:#2C333A;
    --tg-theme-input-text-color:#fff;
    --tg-theme-body-color: #1E2C3A;
  }