@import '../../styles/variables.scss';

.container {
    display: flex;
    align-items:stretch;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-left: $gap-s-neg;
    margin-right: $gap-s-neg;
}

.left {
    flex: 0 0 65%;
    width: 65%;
    padding: 0 $gap-s;

    @media (max-width: $md-width) {
        flex: 0 0 100%;
        width: 100%;
    }
}

.full{
    width: 100%;
    margin-top: $gap-s;
}

.right {
    flex: 0 0 35%;
    width: 35%;
    padding: 0 $gap-s;

    @media (max-width: $md-width) {
        flex: 0 0 100%;
        width: 100%;
    }
}
