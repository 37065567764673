@import '../../../styles/variables.scss';

.blog {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.items {
    display: flex;
    flex-direction: row;
    row-gap: 40px;
    column-gap: 20px;
    flex-wrap: wrap;
    justify-content: center;

    @media (min-width: 836px) {
        justify-content: flex-start;
    }
}

.item {
    max-width: 392px;
    width: 100%;
    background: #FFF;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.25);
}

.images {
    padding: 30px 30px 0;
    font-size: 0;
}

.author {
    display: flex;
    flex-direction: row;
    gap: 30px;
    padding: 9px 30px;
    background: #006BF6;
    color: #fff;


    &__img {
        max-width: 100%;
        width: 65px;
    }

    &__name {
        @include fontStyle($font, 18px, 24px, 400);
    }

    &__title {
        @include fontStyle($font, 15px, 18px, 400);
    }

    &__date {
        margin-top: 4px;
        @include fontStyle($font, 13px, 18px, 700);
    }
}

.title {
    padding: 30px;
    @include fontStyle($font, 24px, 32px, 700);
    color: #242729;
}