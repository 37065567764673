@import '../../../../styles/variables.scss';

.notification {
    position: relative;
    cursor: pointer;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    margin-left: 10px;
    margin-right: 20px;

    &_ico {
        position: relative;
        display: flex;
        align-items: center;

        &:hover {
            color: $primary;
        }
    }

    &_bagle {
        display: block;
        position: absolute;
        top: 0px;
        right: -2px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #ee335e;

        &:before {
            content: "";
            top: -4px;
            right: -4px;
            width: 15px;
            height: 15px;
            position: absolute;
            border-radius: 50%;
            background: rgba(238, 51, 94, 0.8);
            cursor: pointer;
            box-shadow: 0 0 0 rgba(238, 51, 94, 0.9);
            animation: pulse 2s infinite;
        }
    }

    &_wrap {
        position: absolute;
        min-width: 420px;
        right: -30px;
        top: 40px;
        z-index: 999;
        display: none;

        @media (max-width: 550px) {
            min-width: 100%;
            right: -95px;
        }
    }
}

.active {
    display: block !important;
}


@keyframes pulse {
    0% {
        -webkit-transform: scale(0);
        opacity: 0;
    }

    8% {
        -webkit-transform: scale(0);
        opacity: 0;
    }

    15% {
        -webkit-transform: scale(0.1);
        opacity: 1;
    }

    30% {
        -webkit-transform: scale(0.5);
        opacity: 1;
    }

    100% {
        opacity: 0;
        -webkit-transform: scale(1.1);
    }
}

.bodyEmpty {
    max-width: 420px;
    margin-top: 15px;
    min-width: 240px;
    padding: 15px 0;
    text-align: left;
    list-style-type: none;
    background-color: #fff;
    color: #bbb;
    background-clip: padding-box;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
        0 6px 16px 0 rgba(0, 0, 0, 0.08),
        0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.body {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow: auto;

    max-width: 420px;
    margin-top: 15px;
    min-width: 320px;
    padding: 4px 0;
    text-align: left;
    list-style-type: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 2px;
    outline: none;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
        0 6px 16px 0 rgba(0, 0, 0, 0.08),
        0 9px 28px 8px rgba(0, 0, 0, 0.05);

    &:hover {
        &::-webkit-scrollbar-thumb {
            background: #767676;
        }
    }

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: transparent;
    }
}

.ls {
    display: flex;
    flex-direction: row;
    padding: 10px;
    cursor: pointer;

    &:hover {
        background: #f5f5f5;
    }

    &+& {
        border-top: 1px dashed #e9ebec;
    }

    &_img {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        background: #eee;
        color: #777;
    }

    &_body {
        margin-left: 15px;
        display: flex;
        flex-direction: column;
        width: calc(100% - 55px);
    }

    &_txt {
        color: $main-color;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.2;
    }

    &_time {
        font-size: 0.9em;
        color: $gray2;
    }
}

.newBagle {
    position: absolute;
    right: -3px;
    top: -3px;
    width: 12px;
    height: 12px;
    background: #ff4d4f;
    border: 3px solid #fff;
    border-radius: 50%;
    box-shadow: 0 0 0 1px #fff;
    content: '';
    display: block;
}

.message {
    background: rgba(80, 195, 230, .18);
    color: #50c3e6;
}

.sendReview {
    background: #fcead3;
    color: darken(#fcead3, 20%);
    box-shadow: 0 5px 10px #fcead3;
}

.fillInCompanyDetails {
    background: #fffbe6;
    box-shadow: 0 5px 10px #fffbe6;

    &>svg {
        color: #f5ad42;
    }
}

.sendEditCompany {
    background: #bfd4fb;
    box-shadow: 0 5px 10px #bfd4fb;

    &>svg {
        color: #87a7e2;
    }
}

.sendErrorCompany,
.userTenderWin {
    background: #fae2e3;
    box-shadow: 0 5px 10px #fae2e3;

    &>svg {
        color: darken(#fae2e3, 20%);
    }
}

.sendAccessCompany,
.executorUpdateStatus {
    background: #ddf6d9;
    box-shadow: 0 5px 10px #ddf6d9;

    &>svg {
        color: darken(#ddf6d9, 20%);
    }
}

.newMessageOrder {
    background: #e2dafc;
    box-shadow: 0 5px 10px #e2dafc;

    &>svg {
        color: darken(#e2dafc, 20%);
    }
}

.newTenderOrder {
    background: #edc2e9;
    box-shadow: 0 5px 10px #edc2e9;

    &>svg {
        color: darken(#edc2e9, 20%);
    }
}

.orderRefund {
    background: #ffd588;
    box-shadow: 0 5px 10px #ffd588;

    &>svg {
        color: darken(#ffd588, 20%);
    }
}


.loading {
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    border-radius: 4px;
}

.nomessage {
    color: #bbb;
    text-align: center;
    display: block;
}