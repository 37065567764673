@mixin button-color($bg, $color) {
    border-color: $bg;
    background: $bg;
    box-shadow: 0px 2px 3px darken($bg, 10%);
    color: #fff;
    height: auto;
    width: 100%;
    border: 0;
    padding: 7px 10px;
    font-size: 1em;
    outline: 0;

    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    user-select: none;
    touch-action: manipulation;
    border-radius: 2px;

    &:hover,
    &:focus,
    &:active {
        color: $color;
        background: darken($bg, 10%);
        box-shadow: 0px 2px 3px darken($bg, 20%);
    }
}

@mixin button-color-link($color, $bg_hover: #f8f6f6) {
    border: 0 solid transparent;
    background: transparent;
    box-shadow: none;
    color: $color;
    height: auto;
    padding: 7px 10px;
    font-size: 1em;
    outline: 0;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    user-select: none;
    touch-action: manipulation;
    border-radius: 2px;

    &:hover,
    &:focus,
    &:active {
        color: darken($color, 10%);
        background: $bg_hover;
    }
}
