@import '../../../../styles/variables.scss';

.wrap {
    display: table-row;
    width: 100%;
    height: 73px;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e9ebec;
    padding: 15px;
}

.info {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1 0 auto;
}

.back {
    position: relative;
    flex: 0 0 auto;
    color: #000;
    padding-right: 18px;
    margin-right: 18px;
    display: none;

    @media (max-width: 767px) {
        display: block;
    }

    &::before {
        position: absolute;
        top: -22px;
        right: 0;
        width: 1px;
        height: 74px;
        background: #e9ebec;
        content: '';
        display: block;
    }
}

.setting {
    color: $gray2;
    cursor: pointer;
    flex: 0 0 auto;

    &__ico {
        position: relative;
        top: -4px;
        color: $gray2;

        &:hover {
            color: darken($gray2, 30%);
        }
    }
}

.title {
    font-size: 1em;
    font-weight: 500;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 170px;

    @media (min-width: 390px) {
        width: 200px;
    }

    @media (min-width: 480px) {
        width: 80%;
    }

    @media (min-width: 767px) {
        width: 90%;
    }

    @media (min-width: 992px) {
        width: 100%;
    }


    &:hover {
        color: $active;
    }
}

.size {
    font-size: 0.9em;
    color: $gray2;
}