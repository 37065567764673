@import '../../../../styles/variables.scss';


.item {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 10px 10px;
    cursor: pointer;

    &:hover {
        background: #f7f8ff;
    }
}

.item+.item {
    border-top: 1px dashed #e9ebec;
}

.content {
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    width: calc(100% - 50px);
}

.num {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    font-size: 1.1em;
    border-radius: 50%;
    background: #eee;
}

.title {
    font-size: 1.1em;
    color: #222;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.desc {
    font-size: 0.9em;
    color: #878a99;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    // background: #f8fcff;
    // padding: 5px 10px;
    border-radius: 4px;
    margin-top: 5px;
}