@import '../../../styles/variables.scss';

$active1: #006bf6;

.hidden {
    display: none !important;
}

.wrap {
    margin-bottom: $gap-l;
    background: #fff;
    width: 100%;
    transition: all 1s;
    display: flex;
    justify-content: space-between;
    border: 1px solid rgba(4, 32, 69, 0.141);

    @media (max-width: 991px) {
        flex-direction: column;
    }

    &:hover {
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
            rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }
}

.title {
    color: #909ebb;
    padding-bottom: 3px;
    word-break: break-word;
    font-size: 11px;
    line-height: 16px;
    font-style: normal;
    font-weight: 400;
}

.desc {
    color: #334059;
    font-size: 13px;
    line-height: 20px;
    font-style: normal;
    font-weight: 400;
}

.zakazchik {
    color: #747474;
    font-size: 13px;
    line-height: 20px;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
        color: darken(#747474, 20%);
    }
}

.item + .item {
    margin-top: 15px;
}

.item {
    display: flex;
    flex-direction: column;
}

.content {
    width: calc(100% - 250px);
    padding: $gap-l;

    @media (max-width: 991px) {
        width: 100%;
        padding-bottom: 10px;
    }

    &_title {
        cursor: pointer;
        color: $active1;
        font-size: 19px;
        line-height: 24px;
        font-style: normal;
        font-weight: 500;
        margin-bottom: 30px;

        @media (max-width: 991px) {
            font-size: 18px;
        }

        &:hover {
            color: darken($active1, 20%);
        }
    }
}

.info {
    width: 100%;
    max-width: 250px;
    border-left: 1px solid #e4e7f2;
    padding: $gap-l;

    @media (max-width: 991px) {
        max-width: 100%;
        padding-top: 0;
        border-left: 0;
    }
}

.acc {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;

    &:hover {
        color: $active;
    }

    &__ava {
        margin-right: 10px !important;
        max-width: 24px;
    }

    &__name {
        width: calc(100% - 34px);
    }
}
