@import '../../../styles/variables.scss';

.item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 26px;
    gap: 14px;
    transition: all 1s;
    cursor: pointer;
    background: #ffffff;
    border-bottom: 5px solid #feb351;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
    border-radius: 15px;

    &:hover {
        border-bottom: 5px solid darken(#feb351, 5%);
    }
}

.bg {
    background: #fff;
}

.container {
    width: 100%;
    max-width: $container-w;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    flex: 1 1 auto;
}

.row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-left: $gap-m-neg;
    margin-right: $gap-m-neg;

    @media (max-width: $sm-width) {
        flex-direction: column-reverse;
    }
}

.grid {
    width: 100%;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));

    @media (max-width: 480px) {
        grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    }
}

.new {
    position: absolute;
    top: 14px;
    right: -5px;
    padding: 7px 9px;
    font-family: $font1;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    background: #f84e57;
}

.icon {
    margin-right: 7px;
    margin-top: 2px;
}

.clock {
    color: #2462d2;
    display: none !important;
}

.tag {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #828282;
    font-family: $font1;
    margin-right: 5px;
}

.header {
    display: block;

    &__tags {
        margin-top: 25px;
        padding: 0;
    }

    &__title {
        @include fontStyle($font1, 24px, 32px, 700);
        @include textPreview(64px, 2);
        color: #242729;
        transition: all 0.5s;

        &:hover {
            color: $active;
        }
    }
}

.body {
    color: #193656;
    word-break: break-word;

    @include fontStyle($font1, 15px, 20px, 400);
    @include textPreview(60px, 3);
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    &__left {
        display: flex;
        flex-wrap: nowrap;
        line-height: 0.8;
        align-items: center;
        width: calc(100% - 130px);
        font-family: 'Calibri', $font1;
        font-weight: 400;
        font-size: 16px;
    }

    &__right {
        position: relative;
        font-family: 'Calibri', $font1;
        font-weight: 400;
        font-size: 16px;
        text-align: right;
        width: 130px;
        color: #2462d2;

        span {
            position: relative;

            &:before {
                position: absolute;
                left: -17px;
                top: 6px;
                border-radius: 50%;
                width: 8px;
                height: 8px;
                background: #ff7d44;
                content: '';
                display: block;
            }
        }
    }
}

.buttonNext {
    margin: 50px auto 0;
    display: table;
    padding: 17px 28px;
    border: 1px solid #006bf6;
    border-radius: 10px;
    font-family: $font1;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #006bf6;
    background: transparent;
    cursor: pointer;
    transition: all 0.8s;

    &:hover {
        border: 1px solid darken(#006bf6, 20%);
        color: darken(#006bf6, 20%);
    }
}

.searchs {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 18px;

    @media (min-width: 768px) {
        flex-direction: row;
    }

}

.btn_primary {
    background: #006bf6;
    border-radius: 10px;
    color: #ffffff;
    border: 0;
    padding: 17px 26px;
    cursor: pointer;
    transition: all 1s;
    display: inline-block;
    white-space: nowrap;
    @include fontStyle($font1, 18px, 21px, 700);

    @media (min-width: 480px) {
        @include fontStyle($font1, 20px, 23px, 700);
    }

    &:hover {
        color: #fff;
        background: darken(#006bf6, 7%);
    }
}

.search {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;

    &_input {
        max-width: 378px;
        width: 100%;
        padding: 14.5px 25px;
        color: #006bf6;
        border-radius: 10px;
        border: 1px solid #006bf6;
        @include fontStyle($font1, 18px, 21px, 700);

        @media (min-width: 480px) {
            @include fontStyle($font1, 20px, 23px, 700);
        }
    }

    &_btn {
        cursor: pointer;
        background: #006bf6;
        border-radius: 10px;
        color: #ffffff;
        border: 0;
        padding: 17px 26px;
        cursor: pointer;
        transition: all 1s;
        display: inline-block;
        @include fontStyle($font1, 18px, 21px, 700);

        @media (min-width: 480px) {
            @include fontStyle($font1, 20px, 23px, 700);
        }

        &:hover {
            color: #fff;
            background: darken(#006bf6, 7%);
        }
    }
}