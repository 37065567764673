@import '../../styles/variables.scss';

.form {
    margin-bottom: $gap-2xl;
}

.title{
    padding: 1.1em 1.3em;
    border-bottom: 1px solid #eee;
    margin-bottom: 0 !important;
}
.content{
    padding: 1.1em 1.3em;
}