@import '../../styles/variables.scss';

.row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    margin-left: $gap-m-neg;
    margin-right: $gap-m-neg;
    height: 88vh;

    @media (max-width: $sm-width) {
        flex-direction: column-reverse;
    }

    @media (max-width: 767px) {
        position: relative;
    }

    @media (max-width: 480px) {
        margin-left: 0;
        margin-right: 0;
        overflow: hidden;
    }
}

.left {
    width: 30%;
    flex: 0 0 30%;
    padding: 0 $gap-m;
    height: 85vh;
    max-height: 700px;

    @media (max-width: $sm-width) {
        width: 100%;
        flex: 0 0 100%;
    }
}

.right {
    width: 70%;
    flex: 0 0 70%;
    padding: 0 $gap-m;

    @media (max-width: $sm-width) {
        width: 100%;
        flex: 0 0 100%;
    }
}