.tg-page-create-tender{
    background: var(--tg-theme-bg-color);
    color: var(--tg-theme-text-color);
    padding: 20px 10px;
    margin-top: 20px;
    // label{
    //     color: var(--tg-theme-text-color) !important;
    // }
    // textarea,
    // input{
    //     background: #2C333A;
    //     border-color: #2C333A;
    //     color: #fff;
    // }
}

.tg-page-create-tender .ant-form-item-label > label{
    color: var(--tg-theme-text-color);
}

.tg-page-create-tender .ant-input-number-in-form-item{
    border-color: var(--tg-theme-input-bg-color);
}

.tg-page-create-tender .ant-input-number-input,
.tg-page-create-tender .ant-form-item-control-input-content .ant-input{
    color: var(--tg-theme-input-text-color);
    background: var(--tg-theme-input-bg-color);
    border-color: var(--tg-theme-input-bg-color);
}
.tg-page-create-tender .ant-form-item-explain,
.tg-page-create-tender .ant-form-item-extra{
    color: var(--tg-theme-text-color);
}