@import '../../../styles/variables.scss';

.header {
    background-color: $header-bg;
    box-shadow: inset 0 calc(-1 * 1px) 0 0 #dadfe5;
    padding-bottom: 1px;
    z-index: 1;
}

.logo {
    width: 170px;
}

.content {
    width: 100%;
    max-width: $container-w;
    margin-left: auto;
    margin-right: auto;
    padding: 0 $gap-m;
    display: grid;
    align-items: center;
    grid-template-columns: 170px calc(100% - 190px);
    grid-gap: 20px;

    @media (max-width: 480px) {
        grid-template-columns: 140px calc(100% - 150px);
        grid-gap: 10px;
    }
}

.nav {
    display: grid;

    @media (min-width: 320px) {
        grid-template-columns: 40px 30px 30px;
        justify-content: flex-end;
    }

    @media (min-width: 768px) {
        justify-content: center;
        grid-template-columns: minmax(200px, 1fr) 50px 60px;
    }

    @media (min-width: 992px) {
        grid-template-columns: minmax(200px, 1fr) 50px 170px;
    }

    &_gues {
        display: block;

        .menu {
            display: flex !important;
            justify-content: flex-end;
        }
    }
}

.menu {
    @media (min-width: 768px) {
        display: flex;
        justify-content: flex-end;
        line-height: 60px !important;
    }

    // &.ant-menu-horizontal {
    //     display: flex;
    //     flex: 1 1 auto;
    //     justify-content: flex-end;
    //     min-width: 500px;
    // }

    // @media (max-width: 480px) {
    //     display: block !important;
    // }

    &_wrap {
        @media (max-width: 767px) {
            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #fff;
            z-index: 999;
            display: none;
        }

        &__open {
            display: block;
        }
    }

    &_ico {
        margin: 10px 13px;
        font-size: 20px;
        color: #000;
        cursor: pointer;

        @media (min-width: 768px) {
            display: none;
        }

        &__close {
            float: right;
            margin: 10px 13px;
            font-size: 20px;
            color: #000;
            cursor: pointer;

            @media (min-width: 768px) {
                display: none;
            }
        }
    }
}

.profileName {
    margin-left: $gap-m;
    font-size: 14px;
    max-width: 180px;
    line-height: 0.9;

    @media (max-width: 991px) {
        display: none;
    }
}

.profileImage {
    border: 2px solid #ffffff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    object-fit: cover;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 991px) {
        margin-right: 10px;
    }
}

.profile {
    cursor: pointer;
    display: flex;
    flex: 0 0 auto;
    flex-direction: row;
    align-items: center;
}

.profileAva {
    width: 40px !important;
}

.menuIcon {
    position: relative;
    top: 7px;
}
