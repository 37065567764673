@import '../../styles/variables.scss';

.bottomBlock {
    margin-top: $gap-s;
    margin-left: $gap-m-neg;
}

.link {
    margin-right: $gap-s;
}
.social {
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 10px;
}

.form {
    width: 100%;
}

.formContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px 2.5rem 2.5rem 2.5rem;
}
