@import '../../../../styles/variables.scss';

.wrap{
    display: table-row;
    height: 100%;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.empty{
    margin-top: 70px!important;
    margin-bottom: 70px!important;
}

.notification{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: 10px;
    z-index: 1;
    &_wrap{
        position: relative;
    }
}

.waiting{
    background: #e9ae55;
    color: #fff;
}
.enabled{
    background: #2dd48f;
    color: #fff;
}

.loading{
    margin: 20px 0;
    margin-bottom: 20px;
    padding: 30px 50px;
    text-align: center;
    border-radius: 4px;
}

.messageBodyList{
    display: flex;
    flex-direction: column-reverse;
    background-color: #fffefe;
    height: auto;
    &:hover {
        &::-webkit-scrollbar-thumb {
            background: #767676;
        }
    }
    &::-webkit-scrollbar {
        width: 6px;
    }
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: transparent;
    }
}

.chat_head{
    padding: 15px;
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    &__title{
        font-size: 1.2em;
        font-weight: bold;
    }
}

.ico_on{
    color: $active;
    cursor: pointer;
}
.ico_off{
    color: #777;
    cursor: pointer;
}

.nomessage{
    color: #bbb;
    text-align: center;
    display: block;
}

.message{
    padding: 20px;
    border-top: 1px solid #eee;
    background: #fff;
}

.messageInput{
    margin-bottom: 10px;
}

.messageButtom{
    text-align: right;
}

.chat{
    &_body{
        padding: 10px 15px;
    }
}

.noRead{
    background: #f1f7fffa;
}

.msg{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    &:hover &__setting,
    &:hover &__time{
        opacity: 1;
    }
    &__avatar{
        cursor: pointer;
        box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
        border-radius: 50%;
    }
    &__body{
        margin: 0px 10px 0 15px;
        padding-left: 10px;
        display: block;
        overflow: hidden;
        flex: 1 1 auto;
        border-radius: 3px;
    }

    &__time{
        opacity: 0;
        margin-left: 10px;
        font-size: 0.7em;
        color: #434343;
    }
    &__setting{
        opacity: 0;
        float: right;
        margin-top: 2px;
        color: #434343;
        cursor: pointer;
    }
    &__author{
        color: $active;
        font-weight: 500;
    }
    &__content{
        // background-image: linear-gradient(80deg, #006cf6, #0962d4);padding: 0.7rem 1rem;
        clear: both;
        // background-repeat: repeat-x;
        // border-radius: 0.357rem;
        // box-shadow: 0 4px 8px 0 rgba(34, 41, 47, 0.12);
        // color: #fff;
        white-space: pre-line;
        & > p{
            margin: 0;
            color: inherit;
        }
    }
}