@import '../../../styles/variables.scss';

.pagination {
    margin-top: $gap-l;
}

.grid {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));

    @media (max-width: 767px) {
        grid-template-columns: 1fr;
    }
}

.order {
    &_item {
        display: flex;
        flex-direction: column;

        @media (max-width: 767px) {
            margin-bottom: 10px;
        }
    }

    &_header {
        color: #181c32;
        font-size: 1.2em;
        font-weight: bold;
        line-height: 1;
        cursor: pointer;

        &:hover {
            color: $active;
        }
    }

    &_body {
        margin-top: 10px;
        margin-bottom: 10px;
        color: #5e6278;
        font-size: 1em;
        line-height: 1.8;
        font-weight: 400;
    }

    &_footer {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    }
}

.account {
    display: flex;
    flex-direction: row;

    &__img {
        margin-right: 7px;

        &>span {
            border-radius: 4px;
        }
    }

    &__body {
        flex: 1;
        flex-direction: column;
    }

    &__name {
        font-weight: 500;
        color: #181c32;
        margin-bottom: 4px;
    }

    &__time {
        color: #a1a5b7;
    }
}

.categories {
    margin-top: 5px;
    margin-bottom: 5px;

    &>span {
        color: #a1a5b7;
        margin-right: 5px;
    }
}