@import '../../../styles/variables.scss';

.tg_tender_list{
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding: 10px;
}

.pagination{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}