@import '../../../styles/variables.scss';

.header {
    background: #fff;

    &__wrapper {
        max-width: 1907px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        padding: 10px 10px;

        @media (min-width: 1001px) {
            gap: 20px;
            padding: 30px 16px;
        }
    }
}

.logo {
    display: inline-block;
    max-width: 106px;

    @media (min-width: 458px) {
        max-width: 148px;
    }

    @media (min-width: 481px) {
        max-width: 228px;
    }
}

.techsupport {
    display: none;
    align-items: center;
    gap: 10px;
    white-space: nowrap;

    @media (min-width: 885px) {
        display: flex;
    }

    &__flex {
        display: flex;
        flex: 1 0 auto;

        @media (min-width: 885px) {
            display: none;
        }
    }

    &__title {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #242729;
    }

    &__phone {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #242729;
        border-bottom: 1px solid #006bf6;
    }
}

.authNav {
    display: flex;
    align-items: center;
    gap: 20px;

    &__a {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #242729;

        &:first-child {
            @media (max-width: 992px) {
                display: none;
            }
        }

        &:nth-child(2) {
            @media (max-width: 767px) {
                display: none;
            }
        }
    }

    &__btn {
        padding: 6px 15px;
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        line-height: 23px;
        color: #fff;
        background: #006bf6;
        border-radius: 5px;

        &:hover {
            color: #fff;
            background: #2462d2;
        }
    }
}