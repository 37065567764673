@import '../../../styles/variables.scss';

.form {
    margin-bottom: $gap-2xl;
}

.title {
    padding: 1.1em 1.3em;
    border-bottom: 1px solid #eee;
}

.content {
    padding: 1.1em 1.3em;
}

.info {
    color: #777777;
}

.confirm {
    margin-bottom: 20px;
    margin-top: -10px;

    span {
        font-weight: bold;
        color: $active;
        cursor: pointer;
    }

    &_success {
        margin-bottom: 20px;
        margin-top: -10px;
        font-weight: bold;
        color: $grin-color;
    }
}

.securityPhone {
    margin-top: 20px !important;
}

.visible {
    display: block;
}

.hidden {
    display: none !important;
}