@import '../../../styles/variables.scss';

.title {
    font-size: 1.3em;
    text-align: center;
    margin-bottom: $gap-l;
}

.order {
    &__label {
        text-transform: uppercase;
        font-weight: 600;
        color: #878a99;
        border-top: 1px dashed #e9ebec;
        padding-top: 15px;
        margin-top: 20px;
    }

    &__title {
        font-size: 1.1em;
    }

    &__desctiption {
        font-size: 1em;
    }

    &_btns {
        display: flex;
        justify-content: center;
        margin-top: $gap-l;
    }
}