@import '../../../styles/variables.scss';

.form {
    margin-bottom: $gap-2xl;
}

.title {
    padding: 1.1em 1.3em;
    border-bottom: 1px solid #eee;
}

.filter {
    padding: 0.2em 1.3em 1.1em 1.3em;
}

.content {
    padding: 1.1em 1.3em;
}

.footer {
    padding: 1.1em 1.3em;
}

.tableRow {
    cursor: pointer;
}

.add {
    color: $price-completed;
    font-weight: bold;
}

.writeDowns {
    color: $price-error;
    font-weight: bold;
}

.refund {
    color: $price-refund;
    font-weight: bold;
}

.reserved {
    color: $price-reserved;
    font-weight: bold;
}

.pagination {
    margin: $gap-l $gap-l $gap-l auto;
    display: table;
}

.price {
    padding: 1.1em 1.3em;

    &_val {
        font-weight: bold;
        font-size: 1.5em;

        span {
            color: green;
            background: #f5f5f5;
            padding: 2px 4px;
        }
    }

    &_txt {
        margin-top: 10px;
    }
}

.link {
    cursor: pointer;
    color: #333;

    &:hover {
        color: $primary;
    }

    &_active {
        font-weight: bold;
        color: $primary;
    }
}