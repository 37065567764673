@import '../../../styles/variables.scss';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    background: #f4f5f7;
    gap: 40px;
}

.logo {
    max-width: 220px;
}

.form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 4px;
    background-color: #fff;
    width: 100%;
    max-width: 446px;
    box-shadow: 0 0 4px rgba(24, 36, 51, 0.04);
    border: 1px solid rgba(4, 32, 69, 0.141);
}

.rigister {
    max-width: 550px;
}

.title {
    text-align: center;
    font-size: 24px;
    padding: 2.5rem 2.5rem 10px 2.5rem;
}

.link {
    cursor: pointer;
    color: $primary;
}

.auth_text {
    color: $gray2;
    font-size: 1.1em;
    margin-bottom: 40px;
}
