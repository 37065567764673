@import '../../../styles/variables.scss';


.completed,
.add {
    color: $price-completed;
}

.error,
.writeDowns {
    color: $price-error;
}

.refund {
    color: $price-refund;
}

.reserved {
    color: $price-reserved;
}
