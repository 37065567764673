@import '../../styles/variables.scss';

.row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-left: $gap-m-neg;
    margin-right: $gap-m-neg;

    @media (max-width: $sm-width) {
        flex-direction: column-reverse;
    }
}

.left {
    width: 70%;
    flex: 0 0 70%;
    padding: 0 $gap-m;

    @media (max-width: $sm-width) {
        width: 100%;
        flex: 0 0 100%;
    }
}

.right {
    width: 30%;
    flex: 0 0 30%;
    padding: 0 $gap-m;

    @media (max-width: $sm-width) {
        width: 100%;
        flex: 0 0 100%;
    }
}