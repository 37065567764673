@import '../../../styles/variables.scss';

.container {
    min-height: 100vh;
    background-color: $bg-color;
    display: flex;
    flex-direction: column;
}

.content {
    width: 100%;
    max-width: $container-w;
    margin-left: auto;
    margin-right: auto;
    padding: $gap-4xl $gap-m;
    flex: 1 1 auto;
}
