@import '../../../styles/variables.scss';

.container_center {
    width: 100%;
    max-width: $container-w;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
}

.container_fluid {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px
}

.size_x {
    margin-top: 30px;
    margin-bottom: 30px;
}
.size_s {
    margin-top: 50px;
    margin-bottom: 50px;
}
.size_m {
    margin-top: 60px;
    margin-bottom: 60px;
}
.size_l {
    margin-top: 80px;
    margin-bottom: 80px;
}
.size_xl {
    margin-top: 100px;
    margin-bottom: 100px;
}
.size_xxl {
    margin-top: 124px;
    margin-bottom: 124px;
}
.size_xxxl {
    margin-top: 200px;
    margin-bottom: 200px;
}
