@import '../../../styles/variables.scss';

.header{
    z-index: 1;
    width: 100%;
    padding: 5px $gap-s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo{
    max-width: 200px;
}

.light{
    background-color: #fff;
    border-bottom: 1px solid #eee;
    box-shadow: 0px 0px 4px #d7d7d7;
}

.dark{
    background: var(--tg-theme-bg-color);
    border-bottom: 1px solid var(--tg-theme-bg-color);
}