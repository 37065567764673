@import '../../../styles/variables.scss';

.cart {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.08), 0 4px 6px -2px rgba(0, 0, 0, 0.01);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -2px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    background-color: #ffffff;
    border: 1px solid #e2e8f0;
    border-radius: 0.125rem;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem 1.25rem;
    }

    &__title {
        font-size: 1.3em;
        color: #1e293b;
        font-weight: bold;

        span {
            font-size: 1.1em;
            color: #94a3b8;
            font-weight: 500;
        }
    }

    &__event {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    margin-bottom: 30px;
}