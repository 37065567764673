@import "../../../../styles/variables.scss";

.menu {
    position: relative;
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    max-width: 840px;
}

.overflow_scroller {
    position: relative;
    overflow-x: hidden;
    flex: 1 0 auto;
    margin-right: 20px;
}

.overflow_wrapper {
    position: relative;
    transition: left .3s;
    flex: 0 0 auto;
    // white-space: nowrap;
}

.navigation {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    position: relative;

    li {
        outline: none;
        color: inherit;
        text-decoration: none;
        cursor: pointer;
        position: relative;
    }

    li:not(:last-child) {
        margin-right: 24px;
    }

    &>li>a,
    &>li>div {
        text-decoration: none;
        outline: none;
        transition: color .3s ease;
        position: relative;
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        color: #242729;
        white-space: nowrap;
        line-height: 42px;
    }

    &>li>div {
        gap: 10px;
    }
}

.angle {
    position: absolute;
    cursor: pointer;
    top: 9px;
    color: #00000080;
    z-index: 2;

    &_left {
        left: 0;
        background: linear-gradient(-270deg, #fff 70%, hsla(0, 0%, 100%, 0)) no-repeat;
    }

    &_right {
        right: 0;
        background: linear-gradient(270deg, #fff 70%, hsla(0, 0%, 100%, 0)) no-repeat;
    }
}