@import '../../../styles/variables.scss';

.buttons{
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
    gap: 10px;
    button{
        background: var(--tg-theme-button-color);
        color: var(--tg-theme-button-text-color);
        border: none;
        outline: none;
        cursor: pointer;
        padding: 7px 10px;
        border-radius: 4px;
    }
}