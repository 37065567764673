@import '../../styles/variables.scss';

.formContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px 2.5rem 2.5rem 2.5rem;
}

.form {
    width: 100%;
}
