@import '../../../../styles/variables.scss';


.light{
    background-color: #fff;
    box-shadow: 1px 2px 2px #eee;
}

.dark{
    background: var(--tg-theme-bg-color);
    box-shadow: 1px 2px 2px var(--tg-theme-bg-color);
}

.tender{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px;
    padding: 10px;
    border-radius: 7px;
    &_header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &__author{
        color: var(--tg-theme-text-color);
        font-weight: bold;
    }
    &__price{
        background: green;
        padding: 4px;
        border-radius: 4px;
        color: #fff;
        font-size: 0.9em;
        line-height: 1;
    }
    &_footer{
        display: flex;
        flex-direction: column;
        gap: 4px;
    }
    &_info{
        display: flex;
        flex-direction: row;
        gap: 5px;
        &__title{
            font-weight: bold;
            font-size: 0.9em;
            color: var(--tg-theme-text-color);
        }
        &__value{
            font-size: 0.9em;
            color: var(--tg-theme-text-color);
        }
    }
}