@import '../../styles/variables.scss';

.notification {
    width: 100%;
    max-width: $container-w;
    margin-left: auto;
    margin-right: auto;
    margin-top: $gap-xl;
    padding: 0 $gap-m;
}

.header {
    font-size: 14px;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
}

.description {
    display: flex;
    flex-direction: row;

    &_avatar {
        margin-top: 7px !important;
        flex: 0 0 40px;
    }

    &_info {
        flex: 1 1 auto;
        margin-left: 10px;
    }
}

.author {
    color: #a4dcfa;
    font-weight: bold;
    text-decoration: none;
    font-size: 13.4px;
    cursor: pointer;
    z-index: 2;

    &:hover {
        color: #a4dcfa;
        text-decoration: underline;
    }
}

.msg {
    font-size: 14px;
    margin-top: 3px;
    margin-bottom: 5px;
    line-height: 1.1;
    width: 100%;
    height: 3.4em;
    text-overflow: -o-ellipsis-lastline;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

.alertBot {
    margin-bottom: 30px !important;
    background-color: #ffffff !important;
    border: 1px solid #e7e7e7 !important;
}