@import '../../../styles/variables.scss';

.wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: #fff;
    width: calc(100% - 340px);

    @media (max-width: 767px) {
        display: none;
    }

}