@import '../../styles/variables.scss';
@import '../../styles/mixsin.scss';

.filter {
    margin-bottom: $gap-3xl;
    &__name{
        position: relative;
    }
    &__ico{
        position: absolute;
        right: 0;
        top: 0;

        @media (min-width: 992px){
            display: none;
        }

        &__active{
            transform: rotate(180deg);
        }
    }
    &__body{
        position: relative;
    }
    &__hidde{
        display: none !important;
    }
}

.filterItem {
    width: 100%;
    margin-bottom: $gap-m;
    padding-right: $gap-s;
}

.filterName {
    color: #334059;
    font-weight: 700;
    margin-bottom: 1rem;
}

.input {
    width: 100%;
}

.button {
    @include button-color(#006bf6, #fff);
    font-weight: bold;
}

.button_link {
    @include button-color-link(#444);
    font-weight: 400;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: 0;

    svg {
        margin-right: 7px;
    }
}