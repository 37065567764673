@import "../../../styles/variables.scss";

.title {
    margin-bottom: 70px;
    font-family: $font1;

    &_h2 {
        @include fontStyle($font, 36px, 48px, 700);
        color: #003478;

        @media (max-width: 1000px) {
            line-height: 42px;
        }

        @media (max-width: 768px) {
            font-size: 24px;
            line-height: 28px;
        }
    }

    &_p {
        color: #242729;
        @include fontStyle($font, 18px, 24px, 400);
        max-width: 963px;

        @media (max-width: 991px) {
            font-size: 16px;
            line-height: 20px;
        }
    }
}

.extra {
    margin-top: 24px;
}