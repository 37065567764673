@import '../../styles/variables.scss';

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 15%;
}

.group {
    text-align: center;
}

.text {
    margin-top: $gap-m;
    font-size: 30px;
    line-height: 1.2;
}