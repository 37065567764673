@import '../../styles/variables.scss';

.container {
    max-width: 710px;
    padding: 20px;
}

.gray {
    color: $gray2;
    font-size: 12px;
}

.select {
    margin-bottom: $gap-m !important;
}

.body {
    padding: 20px;
}

.mbTextError {
    margin-bottom: 25px;
}

.txt {
    @media (max-width: 991px) {
        margin-top: 40px;
    }
}