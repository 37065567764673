@import '../../../styles/variables.scss';

.form {
    margin-bottom: $gap-2xl;
}

.title {
    padding: 1.1em 1.3em;
    border-bottom: 1px solid #eee;
}

.content {
    padding: 1.1em 1.3em;
}

.list {
    list-style: none;

    &>li {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding: 5px 0;
        margin: 10px 0;
        flex-wrap: wrap;

        @media (max-width: 480px) {
            flex-direction: column;
        }

        &>ul {
            display: flex;
            padding-left: 30px;
            list-style: none;
            margin-top: 10px;
            margin-bottom: -10px;
            width: 100%;

            &>li {
                border-top: 1px dashed #e9ebec;
                display: flex;
                justify-content: space-between;
                align-items: baseline;
                padding: 5px 0;
                margin: 10px 0;
                flex-wrap: wrap;
                width: 100%;

                @media (max-width: 480px) {
                    flex-direction: column;
                }
            }
        }
    }

    li+li {
        border-top: 1px dashed #e9ebec;
    }

    &_content {
        flex: 1 0 auto;
    }

    &__name {
        font-size: 1.2em;
        font-weight: 500;
    }

    &__desc {
        color: $gray2;
    }

    &__switch {
        flex: 0 0 auto;
        margin-top: 10px;

        @media (max-width: 480px) {
            margin-top: 10px !important;
        }
    }
}