@import '../../../styles/variables.scss';
.container{
    margin: 20px 0px 5px;
    padding: 7px 5px;
    background: var(--tg-theme-bg-color);
    box-shadow: 1px 1px 1px var(--tg-theme-body-color);
}

.title{
    display: flex;
    align-items: center;
    gap: 5px;
    color: var(--tg-theme-text-color);
}
